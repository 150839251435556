import Amplify, { Auth } from 'aws-amplify'
import AWSAppSyncClient from 'aws-appsync/lib'
import AppSyncConfig from './aws-exports.js'

Amplify.configure(AppSyncConfig)

const client: any = new AWSAppSyncClient(
  {
    url: AppSyncConfig.aws_appsync_graphqlEndpoint,
    region: AppSyncConfig.aws_project_region,
    auth: {
      type: 'AWS_IAM',
      credentials: async () => await Auth.currentCredentials()
    }
  },
  {
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
      },
      query: {
        fetchPolicy: 'cache-and-network',
        errorPolicy: 'all'
      },
      mutate: {
        errorPolicy: 'all'
      }
    }
  }
)

export default client
