export const schema = {
  models: {
    User: {
      name: 'User',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        surname: {
          name: 'surname',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        emailAddress: {
          name: 'emailAddress',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        contactNumber: {
          name: 'contactNumber',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        dateOfBirth: {
          name: 'dateOfBirth',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        idNumber: {
          name: 'idNumber',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        role: {
          name: 'role',
          isArray: false,
          type: {
            enum: 'UserRole'
          },
          isRequired: false,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: {
            enum: 'Status'
          },
          isRequired: false,
          attributes: []
        },
        profilePicture: {
          name: 'profilePicture',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        cart: {
          name: 'cart',
          isArray: true,
          type: {
            model: 'UserCartConn'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'user'
          }
        },
        transaction: {
          name: 'transaction',
          isArray: true,
          type: {
            model: 'Transaction'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'user'
          }
        }
      },
      syncable: true,
      pluralName: 'Users',
      attributes: [
        {
          type: 'model',
          properties: {}
        }
      ]
    },
    UserCartConn: {
      name: 'UserCartConn',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        resource: {
          name: 'resource',
          isArray: false,
          type: {
            model: 'Resource'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'userCartConnResourceId'
          }
        },
        user: {
          name: 'user',
          isArray: false,
          type: {
            model: 'User'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'userCartConnUserId'
          }
        },
        voucher: {
          name: 'voucher',
          isArray: false,
          type: {
            model: 'Voucher'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'userCartConnVoucherId'
          }
        }
      },
      syncable: true,
      pluralName: 'UserCartConns',
      attributes: [
        {
          type: 'model',
          properties: {}
        }
      ]
    },
    Resource: {
      name: 'Resource',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        title: {
          name: 'title',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        author: {
          name: 'author',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        publisher: {
          name: 'publisher',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        isbn: {
          name: 'isbn',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        grade: {
          name: 'grade',
          isArray: false,
          type: {
            enum: 'Grade'
          },
          isRequired: false,
          attributes: []
        },
        price: {
          name: 'price',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        document: {
          name: 'document',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        coverImage: {
          name: 'coverImage',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        salePrice: {
          name: 'salePrice',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        subject: {
          name: 'subject',
          isArray: false,
          type: {
            enum: 'Subject'
          },
          isRequired: false,
          attributes: []
        },
        genre: {
          name: 'genre',
          isArray: false,
          type: {
            enum: 'Genre'
          },
          isRequired: false,
          attributes: []
        },
        layout: {
          name: 'layout',
          isArray: false,
          type: {
            enum: 'LayoutType'
          },
          isRequired: false,
          attributes: []
        },
        language: {
          name: 'language',
          isArray: false,
          type: {
            enum: 'Language'
          },
          isRequired: false,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: {
            enum: 'Status'
          },
          isRequired: false,
          attributes: []
        },
        type: {
          name: 'type',
          isArray: false,
          type: {
            enum: 'ResourceType'
          },
          isRequired: false,
          attributes: []
        },
        carts: {
          name: 'carts',
          isArray: true,
          type: {
            model: 'UserCartConn'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'resource'
          }
        },
        units: {
          name: 'units',
          isArray: true,
          type: {
            model: 'Unit'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'topic'
          }
        },
        voucher: {
          name: 'voucher',
          isArray: false,
          type: {
            model: 'ResourceVoucherConn'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_ONE',
            associatedWith: 'resource'
          }
        },
        transactions: {
          name: 'transactions',
          isArray: true,
          type: {
            model: 'TransactionResourceConn'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'resource'
          }
        },
        category: {
          name: 'category',
          isArray: false,
          type: {
            enum: 'LiteratureCategory'
          },
          isRequired: false,
          attributes: []
        }
      },
      syncable: true,
      pluralName: 'Resources',
      attributes: [
        {
          type: 'model',
          properties: {}
        },
        {
          type: 'searchable',
          properties: {}
        }
      ]
    },
    Unit: {
      name: 'Unit',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        name: {
          name: 'name',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        keywords: {
          name: 'keywords',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        objectives: {
          name: 'objectives',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        url: {
          name: 'url',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: {
            enum: 'Status'
          },
          isRequired: false,
          attributes: []
        },
        topic: {
          name: 'topic',
          isArray: false,
          type: {
            model: 'Resource'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'unitTopicId'
          }
        }
      },
      syncable: true,
      pluralName: 'Units',
      attributes: [
        {
          type: 'model',
          properties: {}
        }
      ]
    },
    ResourceVoucherConn: {
      name: 'ResourceVoucherConn',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        voucher: {
          name: 'voucher',
          isArray: false,
          type: {
            model: 'Voucher'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'resourceVoucherConnVoucherId'
          }
        },
        resource: {
          name: 'resource',
          isArray: false,
          type: {
            model: 'Resource'
          },
          isRequired: true,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'resourceVoucherConnResourceId'
          }
        }
      },
      syncable: true,
      pluralName: 'ResourceVoucherConns',
      attributes: [
        {
          type: 'model',
          properties: {}
        }
      ]
    },
    Voucher: {
      name: 'Voucher',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        code: {
          name: 'code',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        description: {
          name: 'description',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: {
            enum: 'Status'
          },
          isRequired: false,
          attributes: []
        },
        usage: {
          name: 'usage',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        voucherPrice: {
          name: 'voucherPrice',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        subjectVoucher: {
          name: 'subjectVoucher',
          isArray: false,
          type: 'Boolean',
          isRequired: false,
          attributes: []
        },
        maxUses: {
          name: 'maxUses',
          isArray: false,
          type: 'Int',
          isRequired: false,
          attributes: []
        },
        expiryDate: {
          name: 'expiryDate',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        resources: {
          name: 'resources',
          isArray: true,
          type: {
            model: 'ResourceVoucherConn'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'voucher'
          }
        },
        transactions: {
          name: 'transactions',
          isArray: true,
          type: {
            model: 'Transaction'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'voucher'
          }
        },
        carts: {
          name: 'carts',
          isArray: true,
          type: {
            model: 'UserCartConn'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'voucher'
          }
        }
      },
      syncable: true,
      pluralName: 'Vouchers',
      attributes: [
        {
          type: 'model',
          properties: {}
        }
      ]
    },
    Transaction: {
      name: 'Transaction',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        peachPaymentReference: {
          name: 'peachPaymentReference',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: {
            enum: 'TransactionStatus'
          },
          isRequired: false,
          attributes: []
        },
        tempTransId: {
          name: 'tempTransId',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        cost: {
          name: 'cost',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        voucher: {
          name: 'voucher',
          isArray: false,
          type: {
            model: 'Voucher'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'transactionVoucherId'
          }
        },
        resource: {
          name: 'resource',
          isArray: true,
          type: {
            model: 'TransactionResourceConn'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'HAS_MANY',
            associatedWith: 'transaction'
          }
        },
        user: {
          name: 'user',
          isArray: false,
          type: {
            model: 'User'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'transactionUserId'
          }
        }
      },
      syncable: true,
      pluralName: 'Transactions',
      attributes: [
        {
          type: 'model',
          properties: {}
        }
      ]
    },
    TransactionResourceConn: {
      name: 'TransactionResourceConn',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        transaction: {
          name: 'transaction',
          isArray: false,
          type: {
            model: 'Transaction'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'transactionResourceConnTransactionId'
          }
        },
        resource: {
          name: 'resource',
          isArray: false,
          type: {
            model: 'Resource'
          },
          isRequired: false,
          attributes: [],
          association: {
            connectionType: 'BELONGS_TO',
            targetName: 'transactionResourceConnResourceId'
          }
        }
      },
      syncable: true,
      pluralName: 'TransactionResourceConns',
      attributes: [
        {
          type: 'model',
          properties: {}
        }
      ]
    },
    Faq: {
      name: 'Faq',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        question: {
          name: 'question',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        answer: {
          name: 'answer',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        status: {
          name: 'status',
          isArray: false,
          type: {
            enum: 'Status'
          },
          isRequired: false,
          attributes: []
        }
      },
      syncable: true,
      pluralName: 'Faqs',
      attributes: [
        {
          type: 'model',
          properties: {}
        }
      ]
    },
    Annotation: {
      name: 'Annotation',
      fields: {
        id: {
          name: 'id',
          isArray: false,
          type: 'ID',
          isRequired: true,
          attributes: []
        },
        createdAt: {
          name: 'createdAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        updatedAt: {
          name: 'updatedAt',
          isArray: false,
          type: 'AWSDateTime',
          isRequired: false,
          attributes: []
        },
        userID: {
          name: 'userID',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        resourceID: {
          name: 'resourceID',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        annotationType: {
          name: 'annotationType',
          isArray: false,
          type: {
            enum: 'AnnotationType'
          },
          isRequired: false,
          attributes: []
        },
        selectionRange: {
          name: 'selectionRange',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        comment: {
          name: 'comment',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        },
        highlightColor: {
          name: 'highlightColor',
          isArray: false,
          type: 'String',
          isRequired: false,
          attributes: []
        }
      },
      syncable: true,
      pluralName: 'Annotations',
      attributes: [
        {
          type: 'model',
          properties: {}
        }
      ]
    }
  },
  enums: {
    UserRole: {
      name: 'UserRole',
      values: ['SUPER_ADMIN', 'ADMIN', 'AGENT', 'CONSUMER', 'VISITOR']
    },
    Status: {
      name: 'Status',
      values: ['ACTIVE', 'DRAFT', 'ARCHIVED', 'SUSPENDED']
    },
    Grade: {
      name: 'Grade',
      values: [
        'GRADE_R',
        'GRADE_1',
        'GRADE_2',
        'GRADE_3',
        'GRADE_4',
        'GRADE_5',
        'GRADE_6',
        'GRADE_7',
        'GRADE_8',
        'GRADE_9',
        'GRADE_10',
        'GRADE_11',
        'GRADE_12',
        'GENERAL'
      ]
    },
    Subject: {
      name: 'Subject',
      values: [
        'MATHEMATICS',
        'MATHEMATICAL_LITERACY',
        'SOCIAL_SCIENCES',
        'NATURAL_SCIENCES',
        'NATURAL_SCIENCES_AND_TECHNOLOGY',
        'SOSIALE_WESTENSKAPPE',
        'ECONOMIC_AND_MANAGEMENT_SCIENCES',
        'HISTORY',
        'GESKIEDENIS',
        'LEWENSORIENTERING',
        'LIFE_SKILLS',
        'ACCOUNTING',
        'REKENINGKUNDE',
        'ENGLISH_HOME_LANGUAGE',
        'SEPEDI_HOME_LANGUAGE',
        'SESOTHO_HOME_LANGUAGE',
        'SETSWANA_HOME_LANGUAGE',
        'SISWATI_HOME_LANGUAGE',
        'TSHIVENDA_HOME_LANGUAGE',
        'XITSONGA_HOME_LANGUAGE',
        'AFRIKAANS_HOME_LANGUAGE',
        'ENGLISH_FAL',
        'ISINDEBELE_HOME_LANGUAGE',
        'ISIXHOSA_HOME_LANGUAGE',
        'ISIZULU_HOME_LANGUAGE',
        'NULL'
      ]
    },
    Genre: {
      name: 'Genre',
      values: ['NOVELS', 'POETRY', 'SHORT_STORIES', 'DRAMA', 'FOLKLORE', 'AFRICAN_PRINT', 'NULL']
    },
    LayoutType: {
      name: 'LayoutType',
      values: ['REFLOWABLE', 'PDF', 'EPUB']
    },
    Language: {
      name: 'Language',
      values: [
        'SEPEDI',
        'SESOTHO',
        'SETSWANA',
        'SISWATI',
        'TSHIVENDA',
        'XITSONGA',
        'AFRIKAANS',
        'ENGLISH',
        'ISINDEBELE',
        'ISIXHOSA',
        'ISIZULU'
      ]
    },
    ResourceType: {
      name: 'ResourceType',
      values: ['TEXTBOOK', 'LITERATURE', 'LEGAL_DOCUMENT', 'LEARNING_MATERIAL']
    },
    TransactionStatus: {
      name: 'TransactionStatus',
      values: ['PENDING', 'COMPLETE', 'FAILED']
    },
    LiteratureCategory: {
      name: 'LiteratureCategory',
      values: ['CHILDREN', 'YOUNG_ADULT', 'ADULT']
    },
    AnnotationType: {
      name: 'AnnotationType',
      values: ['HIGHLIGHT', 'UNDERLINE']
    }
  },
  nonModels: {},
  version: 'eb80cd89329915e401d3bc6fe09c521c'
}
