import React, { useState, FC } from 'react'
import { RouteComponentProps } from 'react-router'
import { Formik } from 'formik'
import { Button, Card, Input, Modal, Col, Row } from 'antd'
import { FullWidthContainer } from 'components'
import { Faq, Status } from 'generated/graphql'

type PropsType = RouteComponentProps & {
  history: History
  onSubmit: (e: any) => any
  initialValues?: Faq
}

const FaqFields: FC<PropsType> = ({ history, onSubmit, initialValues }: PropsType) => {
  const [modalVisible, setModalVisible] = useState(false)

  return (
    <Formik
      initialValues={{
        question: initialValues && initialValues.question ? initialValues.question : '',
        answer: initialValues && initialValues.answer ? initialValues.answer : '',
        status: initialValues && initialValues.status ? initialValues.status : Status.Active
      }}
      onSubmit={(values) => {
        if (initialValues && initialValues.id) {
          onSubmit({
            variables: {
              input: {
                id: initialValues.id,
                question: values.question || null,
                answer: values.answer || null,
                status: values.status
              }
            }
          })
        } else {
          onSubmit({
            variables: {
              input: {
                question: values.question || null,
                answer: values.answer || null,
                status: values.status
              }
            }
          })
        }
      }}
    >
      {({ handleChange, handleSubmit, setFieldValue, values: { question, answer } }) => {
        return (
          <form>
            <Modal
              visible={modalVisible}
              onOk={() => handleSubmit()}
              onCancel={() => setModalVisible(false)}
              okText="Ok"
              cancelText="Cancel"
            >
              Are you sure you would like to save this faq?
            </Modal>
            <Row>
              <FullWidthContainer align="left">
                <Row type="flex" justify="space-between">
                  <Col sm={24} md={24} lg={24}>
                    <Card>
                      <Col sm={24} md={18} lg={18} style={{ paddingRight: '10px' }}>
                        <label>Question</label>
                        <Input
                          type="text"
                          name="question"
                          value={question}
                          id="question"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm={24} md={18} lg={18} style={{ paddingRight: '10px' }}>
                        <label>Answer</label>
                        <Input
                          type="textarea"
                          name="answer"
                          value={answer}
                          id="answer"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </Col>
                    </Card>
                    <br />
                    <Card>
                      <Row>
                        <Button
                          onClick={() => history.push(`/admin/faq-management`)}
                          style={{ marginRight: '10px' }}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="default"
                          onClick={() => {
                            setModalVisible(true)
                          }}
                        >
                          Save
                        </Button>
                        {initialValues && initialValues.id && (
                          <Button
                            style={{ marginLeft: '10px' }}
                            type="default"
                            onClick={() => {
                              setFieldValue('status', Status.Archived)
                              setModalVisible(true)
                            }}
                          >
                            Archive
                          </Button>
                        )}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </FullWidthContainer>
            </Row>
          </form>
        )
      }}
    </Formik>
  )
}

export default FaqFields
