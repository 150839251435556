import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  children: ReactNode
  align?: 'right' | 'center' | 'left'
  paddingTop?: string
  height?: string
}

const Container = styled.div`
  width: 100%;
  height: ${(props: IProps) => (props.height ? `${props.height}` : 'auto')};
  text-align: ${(props: IProps) => (props.align ? `${props.align}` : 'auto')};
  padding-top: ${(props: IProps) => (props.paddingTop ? `${props.paddingTop}` : '0px')};
`

const FullWidthContainer: FC<IProps> = (props: IProps) => {
  return (
    <Container align={props.align} paddingTop={props.paddingTop} height={props.height}>
      {props.children}
    </Container>
  )
}

export default FullWidthContainer
