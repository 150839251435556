import React, { ReactNode, FC } from 'react'
import { NavLink, NavLinkProps } from 'react-router-dom'
import { theme } from '../../theme'
import { convertPixelsToRem } from '../../utils'

type IProps = NavLinkProps & {
  label: string | ReactNode
  color?: string
  size?: string
  margin?: string
}

const LinkWrapper: FC<IProps> = (props: IProps) => {
  return (
    <NavLink
      to={props.to}
      style={{
        color: props.color,
        fontSize: props.size,
        textDecoration: 'none',
        margin: props.margin || `${convertPixelsToRem(10)} ${convertPixelsToRem(30)}`
      }}
      activeStyle={{
        borderBottom: `2px solid ${theme.colors.primary}`
      }}
    >
      {props.label}
    </NavLink>
  )
}

export default LinkWrapper
