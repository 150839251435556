import React, { FC } from 'react'
import ApplicationProvider from './ApplicationProvider.context'
import AuthenticationProvider from './AuthenticationProvider.context'
import NetworkStatusProvider from './NetworkStatusProvider.context'

export const AppProvider: FC = ({ children }) => {
  return (
    <AuthenticationProvider>
      <NetworkStatusProvider>
        <ApplicationProvider>{children}</ApplicationProvider>
      </NetworkStatusProvider>
    </AuthenticationProvider>
  )
}
