import { Auth } from 'aws-amplify'
import { AUTH_USER_TOKEN_KEY } from '../constants'
import { UserRole } from 'generated/graphql'

export const signIn = async (
  email: string,
  password: string,
  handleError: (e: string) => void,
  setLoading: (e: boolean) => void,
  callback: (e: any) => void
) => {
  // clears everything in local storage before logging the user in, to ensure no previous user data is there still.
  window.localStorage.clear()
  Auth.signIn(email, password)
    .then((user) => {
      // Authenticated user
      if (user.signInUserSession) {
        // Authorised user token
        window.localStorage.setItem(
          AUTH_USER_TOKEN_KEY,
          user.signInUserSession.accessToken.jwtToken
        )
      }
      callback(user)
    })
    .catch((err) => {
      if (err && err.code === 'UserNotConfirmedException') {
        handleError('You are not confirmed, please use login screen.')
      } else {
        handleError(err.message)
      }
      setLoading(false)
    })
}

export const signUp = async (
  email: string,
  password: string,
  handleError: (e: string) => void,
  setLoading: (e: boolean) => void,
  setConfirmSignupModal: (e: boolean) => void,
  setShowOptionToResendCode: (e: boolean) => void
) => {
  setLoading(true)
  Auth.signUp({
    username: email,
    password,
    attributes: {
      email
    }
  })
    .then((data: any) => {
      setLoading(false)
      setConfirmSignupModal(true)
    })
    .catch((err) => {
      setLoading(false)
      handleError(err.message)
      if (err.code === 'UsernameExistsException' || err.name === 'UsernameExistsException') {
        setShowOptionToResendCode(true)
      }
    })
}

// confirmSignUp
export const confirmSignUp = async (
  email: string,
  password: string,
  confirmationCode: string,
  createUser: any,
  handleError: (e: string) => void,
  setShowOptionToResendCode?: (e: boolean) => void
) => {
  Auth.confirmSignUp(email, confirmationCode)
    .then((data) => {
      if (data === 'SUCCESS') {
        Auth.signIn(email, password).then((user) => {
          // store user's session
          window.localStorage.setItem(
            AUTH_USER_TOKEN_KEY,
            user.signInUserSession.accessToken.jwtToken
          )
          // Get user id from Cognito
          const { sub } = user.signInUserSession.accessToken.payload
          // Create user in dynamoDb with role
          createUser({
            variables: { input: { id: sub, emailAddress: email, role: UserRole.Consumer } }
          })
        })
      }
    })
    .catch((err) => {
      err.code === 'CodeMismatchException'
        ? handleError(
            'Invalid code. Please provide a valid code or exit and click on resend verification code to get a new one'
          )
        : handleError(err.message)
      setShowOptionToResendCode && setShowOptionToResendCode(true)
    })
}

// resendConfirmSignUp
export const resendConfirmSignUp = (
  email: string,
  success: (err: any) => void,
  handleError: (e: string) => void
) => {
  Auth.resendSignUp(email)
    .then(() => {
      success(true)
    })
    .catch((err) => {
      handleError(err.message)
    })
}
