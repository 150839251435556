import * as React from 'react'
import { ClipLoader } from 'react-spinners'
import { theme } from 'theme'

interface IProps {
  size?: number
  color?: string
}

const Loader = (props: IProps) => (
  <div className="sweet-loading">
    <ClipLoader
      sizeUnit="px"
      size={!props.size ? 20 : props.size}
      color={!props.color ? theme.colors.primary : props.color}
      loading
    />
  </div>
)

export default Loader
