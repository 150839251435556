import React, { useState, FC } from 'react'
import { RouteComponentProps } from 'react-router'
import { Button, Input, Modal, Row, notification } from 'antd'
import { Formik } from 'formik'
import { Status, CreateVoucherComponent, UpdateVoucherComponent } from '../../generated/graphql'

type PropsType = RouteComponentProps & {
  initialValues?: any
  closeModal: () => void
}

const SubjectvoucherPriceFields: FC<PropsType> = ({
  initialValues,
  closeModal
}: PropsType) => {
  const [modalVisible, setModalVisible] = useState(false)

  /**
   * Function to show notifications on save/update record
   * @param notif array with the details of the notification
   * */
  async function showNotification(notif: any) {
    return notification[notif.type]({
      message: notif.message,
      description: notif.description
    })
  }

  return (
    <CreateVoucherComponent
      onCompleted={() => {
        showNotification({
          type: 'success',
          message: 'Saved',
          description: 'Subject price has been successfully saved'
        }).then(() => {
          closeModal()
        })
      }}
      onError={() => {
        showNotification({
          type: 'error',
          message: 'Save Error',
          description: 'There has been an error setting your subject price'
        })
      }}
    >
      {(createVoucher, { error }) => (
        <UpdateVoucherComponent
          onCompleted={() => {
            showNotification({
              type: 'success',
              message: 'Updated',
              description: 'Subject price has been successfully updated'
            }).then(() => {
              closeModal()
            })
          }}
          onError={() => {
            showNotification({
              type: 'error',
              message: 'Update Error',
              description: 'There has been an error updating the subject price'
            })
          }}
        >
          {(updateVoucher, { error }) => (
            <Formik
              initialValues={{
                voucherPrice: initialValues && initialValues.voucherPrice,
                code: 'SUBJECTS_VOUCHER',
                subjectVoucher: true,
                description: 'Voucher for buying whole subjects',
                status: Status.Active
              }}
              onSubmit={(values) => {
                setModalVisible(false)
                if (initialValues && initialValues.id) {
                  updateVoucher({ variables: { input: { ...values, id: initialValues.id } } })
                } else {
                  createVoucher({ variables: { input: { ...values } } })
                }
              }}
            >
              {({ handleChange, handleSubmit, values: { voucherPrice } }) => {
                return (
                  <form>
                    <Modal
                      visible={modalVisible}
                      onOk={() => handleSubmit()}
                      onCancel={() => setModalVisible(false)}
                      okText="Ok"
                      cancelText="Cancel"
                    >
                      Are you sure you would like to set this price for all subjects?
                    </Modal>
                    <p>This will set the price for all Learning Material subjects.</p>
                    <label>Voucher Price</label>
                    <Input
                      type="number"
                      name="voucherPrice"
                      value={voucherPrice}
                      id="voucherPrice"
                      placeholder=""
                      onChange={handleChange}
                    />
                    <br />
                    <Row type="flex" justify="end">
                      <Button onClick={() => closeModal()}>Cancel</Button>
                      <Button
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        type="default"
                        onClick={() => {
                          setModalVisible(true)
                        }}
                      >
                        Save
                      </Button>
                    </Row>
                  </form>
                )
              }}
            </Formik>
          )}
        </UpdateVoucherComponent>
      )}
    </CreateVoucherComponent>
  )
}

export default SubjectvoucherPriceFields
