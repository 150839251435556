import React, { ReactNode, FC } from 'react'
import { PageHeader } from 'antd'
import styled from 'styled-components'
import { theme } from 'theme'

const Wrapper = styled.div`
  .ant-page-header-content {
    /* overflow: unset; */
  }
  .ant-row-flex {
    padding: 0 0 0 0;
  }
`

interface IProps {
  children: ReactNode
}

const PageHeaderWrapper: FC<IProps> = (props: IProps) => {
  const { children } = props
  return (
    <Wrapper>
      <PageHeader
        title=""
        style={{
          background: theme.colors.secondary,
          padding: '0px 0px 12px'
        }}
      >
        {children}
      </PageHeader>
    </Wrapper>
  )
}

export default PageHeaderWrapper
