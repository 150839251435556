import React, { useState, FC, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { Button, Modal, Select } from 'antd'
import ContentContainer from 'components/Layouts/ContentContainer'
import { UserRole, ListUsersComponent } from 'generated/graphql'
import { formatString } from 'utils'
import { ErrorMessage, Loader, SubMenu, Table, TableHeaderContainer } from '../../components'
import NewUser from './newUser'

type PropsType = RouteComponentProps & {
  history?: History
}

const { Option } = Select

const Users: FC<PropsType> = (props: PropsType) => {
  const [newUserModal, toggleNewUserModal] = useState(false)
  const [searchFilter, setSearchFilter] = useState(false)
  const [userFilter, setUserFilter] = useState(UserRole.Consumer)
  const [loadingBtn, toggleLoadingBtn] = useState(false)

  function menuItems() {
    return [
      <Select
        key={1}
        defaultValue={UserRole.Consumer}
        onChange={(value: UserRole) => setUserFilter(value)}
      >
        <Option value={UserRole.SuperAdmin}>{formatString(UserRole.SuperAdmin)}s</Option>
        <Option value={UserRole.Admin}>{formatString(UserRole.Admin)}s</Option>
        <Option value={UserRole.Agent}>{formatString(UserRole.Agent)}s</Option>
        <Option value={UserRole.Consumer}>{formatString(UserRole.Consumer)}s</Option>
      </Select>,
      <Button key={2} type="default" onClick={() => toggleNewUserModal(true)}>
        Add New User
      </Button>
    ]
  }

  return (
    <Fragment>
      <SubMenu submenuItems={menuItems()} />
      <ListUsersComponent variables={{ limit :5000, filter:{role:{eq: userFilter}}}}>
        {({ loading, error, data, refetch }: any) => {
          if (loading) {
            return <Loader />
          }
          if (error) {
            return <ErrorMessage errorMessage="There was a problem loading your data" />
          }
          if (!data || !data.listUsers) {
            return <ErrorMessage errorMessage="There was a problem loading your data" />
          }

          return (
            <Fragment>
              <Modal
                title="Add New User"
                visible={newUserModal}
                onCancel={() => toggleNewUserModal(false)}
                footer={[]}
              >
                {
                  <NewUser
                    refetch={refetch}
                    loadingBtn={loadingBtn}
                    toggleLoading={() => toggleLoadingBtn(!loadingBtn)}
                    closeModal={() => toggleNewUserModal(false)}
                  />
                }
              </Modal>
              <ContentContainer>
                <TableHeaderContainer>
                  <Button
                    type="primary"
                    shape="circle"
                    icon="search"
                    onClick={() => setSearchFilter(!searchFilter)}
                  />
                </TableHeaderContainer>
                <Table
                  data={data.listUsers.items}
                  getTdProps={(state: any, rowInfo: { [key: string]: any }) => {
                    return {
                      onClick: () => {
                        if (rowInfo) {
                          // @ts-ignore
                          window.open(`/admin/user/${rowInfo.original.id}`, "_blank")
                        }
                      }
                    }
                  }}
                  columns={[
                    {
                      Header: 'Name',
                      accessor: 'name',
                      filterable: searchFilter
                    },
                    {
                      Header: 'Surname',
                      accessor: 'surname',
                      filterable: searchFilter
                    },
                    {
                      Header: 'Join Date',
                      accessor: 'createdAt'
                    },
                    {
                      Header: 'Status',
                      accessor: 'status'
                    },
                    {
                      Header: 'Email Address',
                      accessor: 'emailAddress',
                      filterable: searchFilter
                    }
                  ]}
                  noDataText="There are no users at the moment"
                  defaultSortBy={[
                    {
                      id: 'createdAt',
                      desc: true
                    },
                    {
                      id: 'name',
                      desc: true
                    }
                  ]}
                />
              </ContentContainer>
            </Fragment>
          )
        }}
      </ListUsersComponent>
    </Fragment>
  )
}

export default Users
