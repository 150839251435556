const images = {
  sovtechLogo: require('../assets/images/sovtech-logo.png'),
  loginBG: require('../assets/images/loginBg.png'),
  passwordResetBG: require('../assets/images/passwordReset.png'),
  defaultAvatar: require('../assets/images/default-avatar.svg'),
  vivliaLogo: require('../assets/images/logo.png'),
  rootsLogoIcon: require('../assets/images/logo.png'),
  landingBackground: require('../assets/images/bg1.png'),
  placeHolder: require('../assets/images/finance_insurance.jpeg'),
  pdfIcon: require('../assets/images/pdfIcon.png'),
  caps: require('../assets/images/caps.png'),
  foundation: require('../assets/images/foundation.jpg'),
  senior: require('../assets/images/senior.jpg'),
  fet: require('../assets/images/fet.jpg'),
  bookCover: require('../assets/images/bookCover.jpg'),
  emptyState: require('../assets/images/empty.svg'),
  offlineState: require('../assets/images/offline.svg'),
  emptyCover: require('../assets/images/abc.png'),
  submitBackground: require('../assets/images/submit_ticket_img.jpg'),
  catchUpPopUp: require('../assets/images/catchup.png'),
  learningMaterial: require('../assets/images/learning_material.jpg'),
  textbook: require('../assets/images/textbook.jpg'),
  printedLessons: require('../assets/images/printed_lessons.jpg'),
  intermediatePhase: require('../assets/images/intermediate_phases.jpg')
}

export default images
