import { Card } from 'antd'
import { Storage } from 'aws-amplify'
import React, { useState, useEffect, FC } from 'react'
import images from '../../theme/images'

type Props = {
  coverImg: string
  src?: string
  resourceTitle: string
  resourcePrice?: string
}

const ResourceCard: FC<Props> = ({
  coverImg,
  resourceTitle,
  resourcePrice,
  children,
  src
}) => {
  const [loadingImg, setLoadingImg] = useState(true)
  const [imgUrl, setImgUrl] = useState('')

  /**
   * Replaced componentDidMount
   * Gets the image key and collects the file from AWS S3 bucket
   */
  useEffect(() => {
    Storage.get(coverImg, { level: 'public' }).then((result) => {
      setLoadingImg(false)
      setImgUrl(result.toString())
    })
  })

  return (
    <Card
      loading={src ? false : loadingImg}
      hoverable
      cover={
        <img
          alt="resource"
          src={src ? src : imgUrl !== '' ? imgUrl : images.emptyCover}
          style={{ width: 200, height: 200, marginLeft: 'auto', marginRight: 'auto', padding: 16 }}
        />
      }
    >
      <p>
        <b>{resourceTitle}</b>
      </p>
      <p>{resourcePrice ? `R ${resourcePrice}` : null}</p>
      {children}
    </Card>
  )
}

export default ResourceCard
