import React, { FC } from 'react'
import { getPackageVersionNumber } from '../../utils'
import { ContainerWrapperInner, ContainerWrapperOuter, VersionNumber } from './styles'

const ContainerWrapper: FC = ({ children }) => (
  <ContainerWrapperOuter role="main">
    <ContainerWrapperInner>{children}</ContainerWrapperInner>
    <VersionNumber>{`v${getPackageVersionNumber()}`}</VersionNumber>
  </ContainerWrapperOuter>
)

export default ContainerWrapper
