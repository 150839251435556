import React, { useState, FC } from 'react'
import { Button, Card, Input, InputNumber, Modal, Select, Col, Row, DatePicker, Tag } from 'antd'
import { RouteComponentProps } from 'react-router'
import moment from 'moment'
import { Formik } from 'formik'
import { ErrorMessage, Loader } from 'components'
import FullWidthContainer from 'components/Layouts/FullWidthContainer'
import {
  Status,
  Voucher,
  CreateResourceVoucherConnComponent,
  ListResourcesComponent,
  DeleteResourceVoucherConnComponent
} from 'generated/graphql'
import { formatString, generateCode, successNotification } from 'utils'

const { Option } = Select

type PropsType = RouteComponentProps & {
  history: History
  onSubmit: (e: any) => any
  initialValues?: Voucher
}

const { TextArea } = Input

const VoucherFields: FC<PropsType> = (props: PropsType) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [newResources, setNewResources] = useState([])

  /**
   * function to show confirmation modal
   * @param status status message
   */
  function setModalConfirmationMessage(status: string) {
    let text = ''
    switch (status) {
      case 'ACTIVE':
        text = 'activate'
        break
      case 'ARCHIVED':
        text = 'archive'
        break
      case 'DRAFT':
        text = 'save'
        break
      default:
        break
    }
    return `Are you sure you would like to ${text} this voucher?`
  }

  /**
   * function to create tags for linked resources
   * @param resource array of resources
   * @param deleteResource gql mutation to delete resource
   */
  function resourceTags(resource: any, deleteResource: any) {
    return resource.map((item: any) => {
      return (
        <Col key={item.id}>
          <Tag closable onClose={() => deleteResource({ variables: { input: { id: item.id } } })}>
            {item.resource.title} - {formatString(item.resource.type)} -{' '}
            {formatString(item.resource.grade)} - {formatString(item.resource.subject, true)}
          </Tag>
        </Col>
      )
    })
  }
  return (

    <CreateResourceVoucherConnComponent>
      {(createResourceVoucherConn) => (
        <DeleteResourceVoucherConnComponent
          onCompleted={() => successNotification('Resource Deleted')}
        >
          {(deleteResource) => (
            <Formik
              initialValues={{
                code:
                  props.initialValues && props.initialValues.code ? props.initialValues.code : '',
                description:
                  props.initialValues && props.initialValues.description
                    ? props.initialValues.description
                    : '',
                status:
                  props.initialValues && props.initialValues.status
                    ? props.initialValues.status
                    : Status.Draft,
                usage:
                  props.initialValues && props.initialValues.usage
                    ? props.initialValues.usage
                    : '0',
                voucherPrice:
                  props.initialValues && props.initialValues.voucherPrice
                    ? props.initialValues.voucherPrice
                    : '',
                maxUses:
                  props.initialValues && props.initialValues.maxUses
                    ? Number(props.initialValues.maxUses)
                    : 0,
                expiryDate:
                  props.initialValues && props.initialValues.expiryDate
                    ? moment(props.initialValues.expiryDate)
                    : undefined
              }}
              onSubmit={(values) => {
                if (props.initialValues && props.initialValues.id) {
                  props.onSubmit({
                    variables: { input: { ...values, id: props.initialValues.id } }
                  })
                } else {
                  props.onSubmit({ variables: { input: { ...values } } }).then((data: any) => {
                    if (data.data && data.data.createVoucher) {
                      return (
                        newResources.map((item: string) => {
                          return createResourceVoucherConn({
                            variables: {
                              input: {
                                resourceVoucherConnVoucherId: data.data.createVoucher.id,
                                resourceVoucherConnResourceId: item
                              }
                            }
                          })
                        }),
                        successNotification('Voucher has been successfully created'),
                        props.history.push('/admin/voucher-management')
                      )
                    }
                  })
                }
              }}
            >
              {({
                handleChange,
                handleSubmit,
                isSubmitting,
                errors,
                setFieldValue,
                values: { code, description, status, maxUses, voucherPrice, expiryDate }
              }) => {
                return (
                  <form>
                    <Modal
                      visible={modalVisible}
                      onOk={() => handleSubmit()}
                      onCancel={() => setModalVisible(false)}
                      okText="Ok"
                      cancelText="Cancel"
                    >
                      {setModalConfirmationMessage(status)}
                    </Modal>
                    <FullWidthContainer align="left">
                      <Card>
                        <Row type="flex" justify="space-between">
                          <Col sm={24} md={11} lg={11}>
                            <label>Description</label>
                            <TextArea
                              name="description"
                              value={description}
                              id="description"
                              placeholder=""
                              onChange={handleChange}
                              rows={7}
                            />
                          </Col>
                          <Col sm={24} md={11} lg={11}>
                            <label>Code</label>
                            <Input
                              type="text"
                              name="code"
                              value={code}
                              id="code"
                              placeholder=""
                              onChange={handleChange}
                            />
                            <Button
                              style={{ marginTop: '20px' }}
                              onClick={() => setFieldValue('code', generateCode())}
                              block
                            >
                              Generate Code
                            </Button>
                            <br />
                            <br />
                            <Row type="flex" justify="space-between">
                              <Col sm={24} md={11} lg={11}>
                                <label>Max Users</label>
                                <br />
                                <InputNumber
                                  type="number"
                                  name="maxUses"
                                  defaultValue={maxUses}
                                  id="maxUses"
                                  min={0}
                                  placeholder=""
                                  onChange={(value) => setFieldValue('maxUses', value)}
                                />
                              </Col>
                              <Col sm={24} md={11} lg={11}>
                                <label>Expiry Date</label>
                                <br />
                                <DatePicker
                                  name="expiryDate"
                                  value={moment(expiryDate)}
                                  id="expiryDate"
                                  format="YYYY-MM-DD"
                                  disabledDate={(current: any) => {
                                    // Can not select days before today and today
                                    return current && current < moment().endOf('day')
                                  }}
                                  onChange={(e: any) => {
                                    setFieldValue('expiryDate', e._d)
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col>
                            {props.initialValues &&
                            props.initialValues.resources &&
                            props.initialValues.resources.items ? (
                              <label>Resources in voucher</label>
                            ) : null}
                            <br />
                            {props.initialValues &&
                            props.initialValues.resources &&
                            props.initialValues.resources.items
                              ? resourceTags(props.initialValues.resources.items, deleteResource)
                              : null}
                          </Col>
                          <Col sm={24} md={24} lg={24}>
                            <ListResourcesComponent variables={{ limit: 5000 }}>
                              {({ loading, error, data }: any) => {
                                if (loading) {
                                  return <Loader />
                                }
                                if ((error && !data) || !data.listResources) {
                                  return (
                                    <ErrorMessage errorMessage="There was a problem loading your data" />
                                  )
                                }
                                const children: any = data.listResources.items
                                  .map((resource: any, key: string) => {
                                    return (
                                      <Option key={key} value={resource.id}>
                                        {resource.title + ' - ' + formatString(resource.grade)}
                                      </Option>
                                    )
                                  })
                                  .filter(function(el: any) {
                                    return el != null
                                  })
                                return (
                                  <Col sm={24} md={24} lg={24}>
                                      <br/>
                                      <label>Resources</label>
                                      <br/>
                                      <Select
                                        showSearch
                                        style={{marginTop:'19px'}}
                                        mode='multiple'
                                        placeholder="Search to Select"
                                        optionFilterProp="children"
                                        id="newResources"
                                        filterOption
                                        value={newResources}
                                        onChange={setNewResources}
                                      >
                                        {children}
                                      </Select>
                                  </Col>
                                )
                              }}
                            </ListResourcesComponent>
                          </Col>
                        </Row>
                      </Card>
                      <br />
                      <Card>
                        <Row>
                          <Button onClick={() => props.history.push('/admin/voucher-management')}>
                            Cancel
                          </Button>
                          {props.initialValues && props.initialValues.id && (
                            <Button
                              type="default"
                              onClick={() => {
                                setFieldValue('status', Status.Archived)
                                setModalVisible(true)
                              }}
                            >
                              Archive
                            </Button>
                          )}
                          <Button
                            style={{ marginLeft: '10px', marginRight: '10px' }}
                            type="default"
                            onClick={() => {
                              setFieldValue('status', Status.Draft)
                              setFieldValue('voucherPrice', '0')
                              setModalVisible(true)
                            }}
                          >
                            Save
                          </Button>
                          <Button
                            type="default"
                            onClick={() => {
                              setFieldValue('status', Status.Active)
                              setFieldValue('voucherPrice', '0')
                              setModalVisible(true)
                            }}
                          >
                            Activate
                          </Button>
                        </Row>
                      </Card>
                    </FullWidthContainer>
                  </form>
                )
              }}
            </Formik>
          )}
        </DeleteResourceVoucherConnComponent>
      )}
    </CreateResourceVoucherConnComponent>
  )
}

export default VoucherFields
