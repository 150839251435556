import React, { FC, Fragment } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button } from 'antd'
import { UpdateFaqComponent, GetFaqComponent } from 'generated/graphql'
import { ContentContainer, Loader, FaqFields, SubMenu } from '../../components'

type PropsType = RouteComponentProps & {
  history: History
  location?: { [key: string]: any }
  computedMatch?: { [key: string]: any }
}

const EditFaq: FC<PropsType> = (props: PropsType) => {
  function menuItems() {
    return [
      <Button key={1} onClick={() => props.history.push(`/admin/content-management`)}>
        Back to all content
      </Button>
    ]
  }
  return (
    <GetFaqComponent
      // @ts-ignore
      variables={{ id: props.computedMatch.params.id }}
    >
      {({ loading, error, data }: any) => {
        if (error) {
          return 'there was a problem loading your data'
        }
        if (loading) {
          return <Loader />
        }
        if (data && data.getFaq) {
          return (
            <Fragment>
              <SubMenu submenuItems={menuItems()} />
              <ContentContainer>
                <UpdateFaqComponent
                  onCompleted={() => {
                    props.history.push(`/admin/faq-management`)
                  }}
                >
                  {(updateFaq, { error }) => (
                    <FaqFields {...props} onSubmit={updateFaq} initialValues={data.getFaq} />
                  )}
                </UpdateFaqComponent>
              </ContentContainer>
            </Fragment>
          )
        } else {
          return
        }
      }}
    </GetFaqComponent>
  )
}

export default withRouter(EditFaq)
