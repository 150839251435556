import React, { FC, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { Button } from 'antd'
import SubMenu from 'components/Header/SubMenu'
import { CreateVoucherComponent, ListVouchersDocument } from 'generated/graphql'
import { VoucherFields, ContentContainer } from '../../components'
import { errorNotification } from 'utils'

type PropsType = RouteComponentProps & {
  history: History
}

const NewResource: FC<PropsType> = (props: PropsType) => {
  function menuItems() {
    return [
      <Button
        key={1}
        type="default"
        onClick={() => props.history.push(`/admin/voucher-management`)}
      >
        Back to all vouchers
      </Button>
    ]
  }

  return (
    <Fragment>
      <SubMenu submenuItems={menuItems()} />
      <ContentContainer>
        <CreateVoucherComponent
          refetchQueries={ListVouchersDocument}
          onError={() => {
            errorNotification('There has been an error creating your voucher')
          }}
        >
          {(createVoucher, { error }) => <VoucherFields {...props} onSubmit={createVoucher} />}
        </CreateVoucherComponent>
      </ContentContainer>
    </Fragment>
  )
}

export default NewResource
