import React, { FC, Fragment } from 'react'
import { withRouter, RouteComponentProps } from 'react-router-dom'
// CSS styling
import { Layout, Row, Col, Button, Input, Dropdown, Menu, Icon } from 'antd'
import images from 'theme/images'
import { Logo, LinkWrapper } from 'components'
import { jwtInfo } from 'utils'
import { Auth } from 'aws-amplify'

const { Header } = Layout

const LandingHeader: FC<RouteComponentProps> = ({ history }) => {
  function menuItems() {
    return (
      <Fragment>
        <LinkWrapper margin="10px" to="/textbook" label="Textbooks" color="#000" />
        <LinkWrapper
          margin="10px"
          to="/explore/literature/children"
          label="Literature"
          color="#000"
        />
        <LinkWrapper margin="10px" to="/learning-material" label="Learning Material" color="#000" />
        {jwtInfo().valid ? (
          <Button
            type="primary"
            onClick={() => {
              Auth.currentSession()
                .then(() => {
                  history.push('/limbo')
                })
                .catch(() => history.push('/sign-in'))
            }}
          >
            Sign Back In
          </Button>
        ) : (
          <Button type="primary" href="/sign-in">
            Login
          </Button>
        )}
        <LinkWrapper margin="10px" to="/contact-us" label="Contact Us" color="#000" />
      </Fragment>
    )
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <LinkWrapper margin="10px" to="/textbook" label="Textbooks" color="#000" />
      </Menu.Item>
      <Menu.Item>
        <LinkWrapper
          margin="10px"
          to="/explore/literature/children"
          label="Literature"
          color="#000"
        />
      </Menu.Item>
      <Menu.Item>
        <LinkWrapper margin="10px" to="/learning-material" label="Learning Material" color="#000" />
      </Menu.Item>
      <Menu.Item>
        {jwtInfo().valid ? (
          <Button
            type="primary"
            onClick={() => {
              Auth.currentSession()
                .then(() => {
                  history.push('/limbo')
                })
                .catch(() => history.push('/sign-in'))
            }}
          >
            Sign Back In
          </Button>
        ) : (
          <Button type="primary" href="/sign-in">
            Login
          </Button>
        )}
      </Menu.Item>
      <Menu.Item>
        <LinkWrapper margin="10px" to="/contact-us" label="Contact Us" color="#000" />
      </Menu.Item>
    </Menu>
  )

  return (
    <Header style={{ background: '#FFF', display: 'contents' }}>
      <Row>
        <Col id="menuLogoContainer" lg={6} md={6} sm={24}>
          <div id="responsiveMenuItems">
            <Dropdown overlay={menu} placement="bottomRight">
              <Button>
                <Icon type="menu" />
              </Button>
            </Dropdown>
          </div>
          <Logo image={images.vivliaLogo} width="60px" />
        </Col>
        <Col lg={6} md={6} sm={24}>
        <label>Search</label>
          <Input
            type="text"
            aria-label="Search"
            placeholder="Search Vivlia"
            onClick={() => history.push('/search')} />
        </Col>
        <Col lg={12} md={12} sm={24}>
          <div id="menuItems">{menuItems()}</div>
        </Col>
      </Row>
    </Header>
  )
}

export default withRouter(LandingHeader)
