import React, { FC, Fragment} from 'react'
import { RouteComponentProps } from 'react-router'
import { Button } from 'antd'
import SubMenu from 'components/Header/SubMenu'
import ContentContainer from 'components/Layouts/ContentContainer'
import { CreateResourceComponent } from 'generated/graphql'
import ContentFields from '../../components/Fields/contentFields'

type PropsType = RouteComponentProps & {
  history: History
}
const NewContent: FC<PropsType> = (props: PropsType) => {
  function menuItems() {
    return [
      <Button
        key={1}
        type="default"
        onClick={() => props.history.push(`/admin/content-management`)}
      >
        Back to all content
      </Button>
    ]
  }
  return (
    <Fragment>
      <SubMenu submenuItems={menuItems()} />
      <ContentContainer>
        <CreateResourceComponent
          onCompleted={() => {
            props.history.push(`/admin/content-management`)
          }}
        >
          {(createResource, { error }) => <ContentFields {...props} onSubmit={createResource} />}
        </CreateResourceComponent>
      </ContentContainer>
    </Fragment>
  )
}

export default NewContent
