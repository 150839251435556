import React, { useState, FC } from 'react'
import { RouteComponentProps } from 'react-router'
/** Presentation/UI */
import { Formik } from 'formik'
import { FullWidthContainer, ErrorMessage } from '..'
import { Input, Button, Row, Col, notification, Tabs, Modal } from 'antd'
import { CreateUserComponent } from 'generated/graphql'
import { theme } from 'theme'
import * as validator from 'validator'
import { signIn, signUp, confirmSignUp, resendConfirmSignUp, handleError } from 'utils'

const { TabPane } = Tabs

type Props = RouteComponentProps & {
  toggleModal: () => void
  successCallback: () => void
  visible: boolean
  history: History
}

const LoginSignupModal: FC<Props> = ({ toggleModal, visible, successCallback }: Props) => {
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const [showOptionToResendCode, setShowOptionToResendCode] = useState(false)
  const [confirmSignupModal, setConfirmSignupModal] = useState(false)
  const [confirmationCodeError, setConfirmationCodeError] = useState('')

  async function showNotification(message: string) {
    return notification['success']({
      message: 'Success',
      description: message
    })
  }

  function validateSignUpForm({ email, password, confirmPassword }: any): boolean {
    // Check for undefined or empty input fields
    if (!email || !password || !confirmPassword) {
      handleError('All fields are required', (e: string) => setError(e))
      return false
    }
    if (password !== confirmPassword) {
      handleError('Passwords do not match.', (e: string) => setError(e))
      return false
    }
    // Validate email
    if (!validator.isEmail(email)) {
      handleError('Please enter a valid email address.', (e: string) => setError(e))
      return false
    }
    return true
  }
  return (
    <Modal visible={visible} onCancel={() => toggleModal()} footer="">
      <FullWidthContainer align="center" height="270px">
        <Tabs defaultActiveKey="1">
          <TabPane tab="Login" key="1">
            <Formik
              initialValues={{
                password: '',
                email: ''
              }}
              onSubmit={(values: any) => {
                if (values.email === '' || values.password === '') {
                  handleError('Email and Password are required.', (e: string) => setError(e))
                }
                setLoading(true)
                signIn(
                  values.email,
                  values.password,
                  (e) => handleError(e, (e: string) => setError(e)),
                  (e) => setLoading(e),
                  (e) => {
                    if (e.challengeName === 'NEW_PASSWORD_REQUIRED') {
                      handleError(
                        'You are required to set a new password, please use the login screen.',
                        (e: string) => setError(e)
                      )
                      setLoading(false)
                    }
                    showNotification('Successfully signed in.')
                    toggleModal()
                    successCallback()
                  }
                )
              }}
            >
              {({ handleChange, handleSubmit, values: { password, email } }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <Row>
                      <FullWidthContainer align="left">
                        <label>Email Address</label>
                        <Input
                          type="text"
                          name="email"
                          value={email}
                          id="email"
                          placeholder=""
                          onChange={handleChange}
                        />
                        <label>Password</label>
                        <Input
                          type="password"
                          name="password"
                          value={password}
                          id="password"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </FullWidthContainer>
                      <br />
                      <br />
                      <FullWidthContainer align="center">
                        <Col span={24}>
                          <Button type="primary" htmlType="submit" loading={loading}>
                            Login
                          </Button>
                        </Col>
                      </FullWidthContainer>
                    </Row>
                    <ErrorMessage errorMessage={error} />
                  </form>
                )
              }}
            </Formik>
          </TabPane>
          <TabPane tab="Sign Up" key="2">
            <Formik
              initialValues={{
                email: '',
                password: '',
                confirmPassword: '',
                confirmationCode: ''
              }}
              onSubmit={(values: any) => {
                if (validateSignUpForm(values)) {
                  setLoading(true)
                  signUp(
                    values.email,
                    values.password,
                    (e) => handleError(e, (e: string) => setError(e)),
                    (e) => setLoading(e),
                    (e) => setConfirmSignupModal(e),
                    (e) => setShowOptionToResendCode(e)
                  )
                }
              }}
            >
              {({
                handleChange,
                handleSubmit,
                values: { password, email, confirmPassword, confirmationCode }
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <CreateUserComponent
                      onCompleted={() => {
                        setConfirmSignupModal(false)
                        showNotification('Successfully created account.')
                        Modal.destroyAll()
                        successCallback()
                      }}
                    >
                      {(createUser) => (
                        <Modal
                          visible={confirmSignupModal}
                          title="Please Check Your Email"
                          onCancel={() => setConfirmSignupModal(false)}
                          footer={[]}
                        >
                          <label>Enter Confirmation Code</label>
                          <Input
                            type="text"
                            name="confirmationCode"
                            id="confirmationCode"
                            placeholder="Confirmation code"
                            onChange={handleChange}
                          />
                          <br />
                          <br />
                          <FullWidthContainer align="center">
                            <Button
                              type="primary"
                              disabled={loading}
                              loading={loading}
                              onClick={() => {
                                confirmSignUp(email, password, confirmationCode, createUser, (e) =>
                                  setConfirmationCodeError(e)
                                )
                              }}
                            >
                              Verify
                            </Button>
                            {error && <ErrorMessage errorMessage={error} />}
                            {confirmationCodeError && (
                              <ErrorMessage errorMessage={confirmationCodeError} />
                            )}
                          </FullWidthContainer>
                        </Modal>
                      )}
                    </CreateUserComponent>
                    <FullWidthContainer align="left">
                      <label>Email</label>
                      <Input
                        type="email"
                        name="email"
                        value={email}
                        id="userEmail"
                        placeholder=""
                        onChange={handleChange}
                      />
                      <label>Password</label>
                      <Input
                        type="password"
                        name="password"
                        value={password}
                        id="userPassword"
                        placeholder=""
                        onChange={handleChange}
                      />
                      <label>Re-enter Password</label>
                      <Input
                        type="password"
                        name="confirmPassword"
                        value={confirmPassword}
                        id="confirmPassword"
                        placeholder=""
                        onChange={handleChange}
                      />
                    </FullWidthContainer>
                    <br />
                    <FullWidthContainer align="center">
                      {showOptionToResendCode ? (
                        <Button
                          loading={loading}
                          type="primary"
                          disabled={loading}
                          color={theme.colors.textSecondary}
                          onClick={() =>
                            resendConfirmSignUp(
                              email,
                              () => setConfirmSignupModal(true),
                              (e) => handleError(e, (e: string) => setError(e))
                            )
                          }
                        >
                          Resend Verification Code
                        </Button>
                      ) : (
                        <Button type="primary" htmlType="submit" loading={loading}>
                          Sign Up
                        </Button>
                      )}
                    </FullWidthContainer>
                  </form>
                )
              }}
            </Formik>
          </TabPane>
        </Tabs>
      </FullWidthContainer>
    </Modal>
  )
}

export default LoginSignupModal
