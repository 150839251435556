import React, { FC, Fragment } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button } from 'antd'
import { UpdateVoucherComponent, GetVoucherWResourcesComponent } from 'generated/graphql'
import { ContentContainer, Loader, SubMenu, VoucherFields } from '../../components'
import { successNotification, errorNotification } from 'utils'

type PropsType = RouteComponentProps & {
  history: History
  location?: { [key: string]: any }
  computedMatch: { params: { id: string; [key: string]: any }; [key: string]: any }
}

const EditVoucher: FC<PropsType> = (props: PropsType) => {
  function menuItems() {
    return [
      <Button key={1} onClick={() => props.history.push('/admin/voucher-management')}>
        Back to all vouchers
      </Button>
    ]
  }

  return (
    <GetVoucherWResourcesComponent variables={{ id: props.computedMatch.params.id }}>
      {({ loading, error, data, refetch }: any) => {
        if (loading) {
          return <Loader />
        }
        if (error || !data || !data.getVoucher) {
          return 'There was a problem loading your data'
        }
        return (
          <Fragment>
            <SubMenu submenuItems={menuItems()} />
            <ContentContainer>
              <UpdateVoucherComponent
                onCompleted={() => {
                  refetch()
                  successNotification('Voucher has been successfully updated')
                  props.history.push('/admin/voucher-management')
                }}
                onError={() => {
                  errorNotification('There has been an error updating the voucher')
                }}
              >
                {(updateVoucher, { error }) => (
                  <VoucherFields
                    {...props}
                    onSubmit={updateVoucher}
                    initialValues={data.getVoucher}
                  />
                )}
              </UpdateVoucherComponent>
            </ContentContainer>
          </Fragment>
        )
      }}
    </GetVoucherWResourcesComponent>
  )
}

export default withRouter(EditVoucher)
