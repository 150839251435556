import React, { Component, ReactNode } from 'react'
import styled from 'styled-components'
import { theme } from 'theme'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  background: ${theme.colors.htmlBackground};
  min-height: 100vh;
  padding: 60px;
  flex-direction: column;
`
export default class BackendWrapper extends Component<{
  children: ReactNode
}> {
  render() {
    const { children } = this.props
    return <Wrapper>{children}</Wrapper>
  }
}
