import React, { ReactNode, FC } from 'react'
import { Button } from 'antd'

interface IProps {
  background?: string
  disabled?: boolean
  loading?: boolean
  color?: string
  label: string | ReactNode
  width?: string
  margin?: string
  className?: string
  type?: 'default' | 'primary' | 'ghost' | 'dashed' | 'danger' | 'link'
  padding?: string
  display?: string
  height?: string
  align?: string
  onClick?: () => void
}

const ButtonWrapper: FC<IProps> = (props: IProps) => {
  return (
    <Button
      disabled={props.disabled}
      loading={props.loading}
      type={props.type}
      className={props.className}
      style={{
        color: props.color,
        background: props.background,
        width: props.width,
        height: props.height,
        margin: props.margin || '0px 2rem 0px 0px',
        padding: props.padding,
        display: props.display,
        borderColor: props.background
      }}
      onClick={props.onClick}
    >
      {props.label}
    </Button>
  )
}

export default ButtonWrapper
