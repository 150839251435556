import React, { FC, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { Button } from 'antd'
import { CreateFaqComponent } from 'generated/graphql'
import { SubMenu, ContentContainer, FaqFields } from '../../components'

type PropsType = RouteComponentProps & {
  history: History
}

const NewFaq: FC<PropsType> = (props: PropsType) => {
  function menuItems() {
    return [
      <Button
        key={1}
        type="default"
        onClick={() => props.history.push(`/admin/content-management`)}
      >
        Back to all content
      </Button>
    ]
  }
  return (
    <Fragment>
      <SubMenu submenuItems={menuItems()} />
      <ContentContainer>
        <CreateFaqComponent
          onCompleted={() => {
            props.history.push(`/admin/faq-management`)
          }}
        >
          {(createFaq, { error }) => <FaqFields {...props} onSubmit={createFaq} />}
        </CreateFaqComponent>
      </ContentContainer>
    </Fragment>
  )
}

export default NewFaq
