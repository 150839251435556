import React, { FC, createContext } from 'react'
import { useNetworkStatus } from '../hooks'

const DEFAULT_STATE = {
  isOnline: true
}

export const NetworkStatusContext = createContext(DEFAULT_STATE)

const Provider: FC = ({ children }) => {
  const { isOnline } = useNetworkStatus()

  return (
    <NetworkStatusContext.Provider value={{ isOnline }}>{children}</NetworkStatusContext.Provider>
  )
}

export default Provider
