import React, { createRef } from 'react'
import { Row, Button } from 'antd'

export default (props: any) => {
  const bodyRef = createRef<HTMLDivElement>()
  const createPdf = () => props.createPdf(bodyRef.current)
  return (
    <section className="pdf-container">
      <section className="pdf-toolbar">
        <Row type="flex" justify="center">
          <Button onClick={createPdf}>Download Invoice</Button>
        </Row>
      </section>
      <section className="pdf-body" ref={bodyRef}>
        {props.children}
      </section>
    </section>
  )
}
