import React, { FC, Fragment} from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button } from 'antd'
import { ResourceType, UpdateResourceComponent } from 'generated/graphql'
import { textbookOrLiterature } from 'utils'
import { ContentContainer, Loader, ResourceFields, SubMenu } from '../../components'
import { GetResourceComponent } from '../../generated/graphql'

type PropsType = RouteComponentProps & {
  history: History
  location?: { [key: string]: any }
  computedMatch?: { [key: string]: any }
}

const EditManagement: FC<PropsType> = (props: PropsType) => {
  function whereAmI() {
    return textbookOrLiterature(props.location.pathname)
  }

  function menuItems() {
    return [
      <Button key={1} onClick={() => props.history.push(`/admin/${whereAmI()}-management`)}>
        Back to all {whereAmI()}s
      </Button>
    ]
  }
  return (
    <GetResourceComponent
      // @ts-ignore
      variables={{ id: props.computedMatch.params.id }}
    >
      {({ loading, error, data, refetch }: any) => {
        if (error) {
          return 'there was a problem loading your data'
        }
        if (loading) {
          return <Loader />
        }
        if (data && data.getResource) {
          return (
            <Fragment>
              <SubMenu submenuItems={menuItems()} />
              <ContentContainer>
                <UpdateResourceComponent
                  onCompleted={() => {
                    refetch()
                    props.history.push(`/admin/${whereAmI()}-management`)
                  }}
                >
                  {(updateResource, { error }) => (
                    <ResourceFields
                      {...props}
                      resourceType={
                        whereAmI() === 'textbook' ? ResourceType.Textbook : ResourceType.Literature
                      }
                      onSubmit={updateResource}
                      initialValues={data.getResource}
                    />
                  )}
                </UpdateResourceComponent>
              </ContentContainer>
            </Fragment>
          )
        } else {
          return
        }
      }}
    </GetResourceComponent>
  )
}

export default withRouter(EditManagement)
