import React, { FC, ReactNode } from 'react'
import { Layout } from 'antd'

interface IProps {
  children: ReactNode
  width?: string
  margin?: string
  padding?: string
  background?: string
}

const { Content } = Layout

const ContentContainer: FC<IProps> = (props: IProps) => {
  return (
    <Content
      style={{
        margin: props.margin || '24px 16px',
        padding: props.padding || 24,
        background: props.background || '#fff',
        minHeight: 'auto',
        width: props.width
      }}
    >
      {props.children}
    </Content>
  )
}

export default ContentContainer
