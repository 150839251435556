import React, { Component } from 'react'
import Lottie from 'react-lottie'

interface IProps {
  anim: object
  loop: boolean
  width: number | string
  height: number | string
}

export default class LottieControl extends Component<IProps> {
  render() {
    const { loop, anim, width, height } = this.props
    const options: any = {
      loop,
      autoplay: true,
      animationData: anim,
      rendererSettings: {
        preserveAspectRatio: true
      }
    }
    return <Lottie options={options} height={height} width={width} />
  }
}
