import { Button, Card, Col, Row, Tabs } from 'antd'
import React, { FC, Fragment } from 'react'
// ui components
import { formatString } from 'utils'
import { FullWidthContainer } from '..'
import { Resource } from 'generated/graphql'
import * as validator from 'validator'

type PropsType = {
  purchased?: boolean
  addToCart: (id: string) => void
  data: Resource
  loadingImg: boolean
  loadingButton: boolean
  imgUrl: string
}

const { TabPane } = Tabs

const LernerResourceDetailsFields: FC<PropsType> = ({
  addToCart,
  data,
  loadingImg,
  imgUrl,
  loadingButton,
  purchased
}: PropsType) => {
  return (
    <Fragment>
      <Row gutter={24}>
        <Col sm={24} md={8} lg={8}>
          <Card
            loading={loadingImg}
            cover={
              <img
                alt="learning"
                src={validator.isURL(imgUrl) ? imgUrl : '../../assets/images/caps.png'}
              />
            }
          />
        </Col>
        <FullWidthContainer align="left">
          <Col sm={24} md={14} lg={14}>
            <h2>{data.title}</h2>
            <p>{data.description}</p>
            <h2>
              R {data.price}{' '}
              {purchased ? null : (
                <Button
                  loading={loadingButton}
                  disabled={loadingButton}
                  icon="shopping-cart"
                  onClick={() => addToCart(data.id)}
                >
                  Add to cart
                </Button>
              )}
            </h2>
            <Card>
              {formatString(data.grade)}
              <br />
              Subject: {formatString(data.subject)}
              <br />
              ISBN: {data.isbn}
              <br />
              Author: {data.author}
              <br />
              Layout Type: {data.layout}
              <br />
              Language: {formatString(data.language)}
              <br />
              Publisher: {data.publisher}
            </Card>
          </Col>
          <Col span={24}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Description" key="1">
                {data.description}
              </TabPane>
              <TabPane tab="How to Access" key="2">
                <p>After successful purchase this item will be added to your library</p>
                <p>You can access your library in the following ways:</p>
                <p>From computer, you can access your library after successful login</p>
                <p>From Android App,you can download the app from here</p>
                <p>From IOS App,you can download the app from here</p>
                <p>
                  For other devices, you can access your library using this web app through a
                  browser on your phone
                </p>
              </TabPane>
            </Tabs>
          </Col>
        </FullWidthContainer>
      </Row>
    </Fragment>
  )
}

export default LernerResourceDetailsFields
