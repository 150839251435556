export const formatString = (toFix: string | null | undefined, allFirstCaps: boolean = false) => {
  try {
    let toFixTemp = ''
    if (toFix) {
      toFixTemp = toFix
    }
    if (allFirstCaps) {
      let sentence = ''
      const temp: string[] = toFixTemp
        .toLowerCase()
        .replace(/_/g, ' ')
        .split(' ')
      temp.forEach((word) => {
        if (word === 'and') {
          word = word.toLowerCase()
        } else if (word.includes('isi', 0)) {
          word =
            word.substr(0, 3).toLowerCase() +
            word.charAt(3).toUpperCase() +
            word.substr(4).toLowerCase()
        } else {
          word = word.charAt(0).toUpperCase() + word.substr(1).toLowerCase()
        }
        sentence += word + ' '
      })
      return sentence
    }
    return (
      toFixTemp.charAt(0).toUpperCase() +
      toFixTemp
        .substr(1)
        .toLowerCase()
        .replace(/_/g, ' ')
    )
  } catch (error) {
    return toFix
  }
}
