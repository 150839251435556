import React, { FC, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { Button } from 'antd'
import SubMenu from 'components/Header/SubMenu'
import ContentContainer from 'components/Layouts/ContentContainer'
import { CreateResourceComponent, ResourceType, ListResourcesDocument } from 'generated/graphql'
import { textbookOrLiterature } from 'utils'
import ResourceFields from '../../components/Fields/resourceFields'

type PropsType = RouteComponentProps & {
  history: History
}

const NewResource: FC<PropsType> = (props: PropsType) => {
  function whereAmI() {
    return textbookOrLiterature(props.location.pathname)
  }

  function menuItems() {
    return [
      <Button
        key={1}
        type="default"
        onClick={() => props.history.push(`/admin/${whereAmI()}-management`)}
      >
        Back to all {whereAmI()}s
      </Button>
    ]
  }
  return (
    <Fragment>
      <SubMenu submenuItems={menuItems()} />
      <ContentContainer>
        <CreateResourceComponent
          refetchQueries={ListResourcesDocument}
          onCompleted={() => {
            props.history.push(`/admin/${whereAmI()}-management`)
          }}
        >
          {(createResource, { error }) => (
            <ResourceFields
              resourceType={
                whereAmI() === 'textbook' ? ResourceType.Textbook : ResourceType.Literature
              }
              {...props}
              onSubmit={createResource}
            />
          )}
        </CreateResourceComponent>
      </ContentContainer>
    </Fragment>
  )
}

export default NewResource
