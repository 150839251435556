// 404 Page
import React, { FC } from 'react'
import { RouteComponentProps } from 'react-router'
import { ContainerWrapper, LottieWrapper } from '../../components'

const PageNotFound: FC<RouteComponentProps> = () => (
  <ContainerWrapper>
    <LottieWrapper
      width={400}
      height="auto"
      loop
      anim={require('../../assets/lottieFiles/me_at_office.json')}
    />
    <h1>Oops...</h1>
    <h2>The thing you are looking for is not a thing.</h2>
    <h3>Our team has been notified of this error and will have it resolved as soon as possible.</h3>
  </ContainerWrapper>
)

export default PageNotFound
