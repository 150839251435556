import { lazy } from 'react'
import NewResource from 'containers/ResourceManagement/newResource'
import EditResource from 'containers/ResourceManagement/editResource'
import Manage from 'containers/Users'
import EditUser from 'containers/Users/editUser'
import NewVoucher from 'containers/VoucherManagement/newVoucher'
import EditVoucher from 'containers/VoucherManagement/editVoucher'
import NewContent from 'containers/ContentManagement/newContent'
import EditContent from 'containers/ContentManagement/editContent'
import NewFaq from 'containers/FaqManagement/newFaq'
import EditFaq from 'containers/FaqManagement/editFaq'

const Landing = lazy(() => import('../containers/Landing'))
const MyLibrary = lazy(() => import('../containers/MyLibrary'))
const AdminDashboard = lazy(() => import('../containers/AdminDashboard'))
const ForgotPassword = lazy(() => import('../containers/ForgotPassword'))
const Login = lazy(() => import('../containers/Login'))
const Profile = lazy(() => import('../containers/Profile'))
const Register = lazy(() => import('../containers/Register'))
const ResourceManagement = lazy(() => import('../containers/ResourceManagement'))
const VoucherManagement = lazy(() => import('../containers/VoucherManagement'))
const ContentManagement = lazy(() => import('../containers/ContentManagement'))
const FaqManagement = lazy(() => import('../containers/FaqManagement'))
const ResourceExploration = lazy(() => import('../containers/ResourceExploration'))
const SubjectExploration = lazy(() => import('../containers/ResourceExploration/subjects'))
const FilteredResources = lazy(() => import('../containers/ResourceExploration/filteredResources'))
const ResourceDetails = lazy(() => import('containers/ResourceExploration/resourceDetails'))
const Cart = lazy(() => import('../containers/Cart'))
const LearningMaterial = lazy(() => import('../containers/LearningMaterial'))
const TopicDetails = lazy(() => import('../containers/LearningMaterial/topicDetails'))
const PublicSearch = lazy(() => import('../containers/Landing/Search'))
const ExploreFaqs = lazy(() => import('../containers/ExploreFaqs'))
const Search = lazy(() => import('../containers/MyLibrary/Search'))
const PaymentStatus = lazy(() => import('../containers/Cart/paymentStatus'))
const Sales = lazy(() => import('../containers/Sales'))
const Limbo = lazy(() => import('../containers/Limbo'))
const ContactUs = lazy(() => import('../containers/ContactUs'))

/* Routes for Consumers
 *  These routes will show on the sidebar
 *  Please take not of the @param positionInNav
 */
const CONSUMER_ROUTES = [
  {
    exact: true,
    icon: 'home',
    path: '/learner/your-library',
    to: '/learner/your-library',
    positionInNav: 1,
    component: MyLibrary,
    title: 'Your Library',
    private: true
  },
  {
    exact: true,
    icon: 'book',
    path: '/learner/textbook',
    to: '/learner/textbook',
    positionInNav: 2,
    component: ResourceExploration,
    title: 'Textbooks',
    private: true
  },
  {
    exact: true,
    icon: 'file-text',
    path: '/learner/literature',
    to: '/learner/literature',
    positionInNav: 3,
    component: ResourceExploration,
    title: 'Literature',
    private: true,
    subMenu: [
      {
        to: '/learner/literature/children',
        title: 'Children'
      },
      {
        to: '/learner/literature/young-adult',
        title: 'Young Adult'
      },
      {
        to: '/learner/literature/adult',
        title: 'Adult'
      }
    ]
  },
  {
    exact: true,
    icon: 'file-protect',
    path: '/learner/learning-material',
    to: '/learner/learning-material',
    positionInNav: 4,
    component: ResourceExploration,
    title: 'Learning Material',
    private: true
  },
  {
    exact: true,
    icon: 'search',
    path: '/learner/search',
    to: '/learner/search',
    positionInNav: 5,
    component: Search,
    title: 'Search',
    private: true
  }
]

/* Routes for Administrators
 *  These routes will show on the sidebar
 *  Please take not of the @param positionInNav
 */
const ADMIN_ROUTES = [
  {
    exact: true,
    icon: 'home',
    path: '/admin/dashboard',
    to: '/admin/dashboard',
    positionInNav: 1,
    component: AdminDashboard,
    title: 'Dashboard',
    private: true
  },
  {
    exact: true,
    icon: 'team',
    path: '/admin/users',
    to: '/admin/users',
    positionInNav: 2,
    component: Manage,
    title: 'Users',
    private: true
  },
  {
    exact: true,
    icon: 'file-add',
    path: '/admin/textbook-management',
    to: '/admin/textbook-management',
    positionInNav: 3,
    component: ResourceManagement,
    title: 'Textbook Management',
    private: true
  },
  {
    exact: true,
    icon: 'file-add',
    path: '/admin/literature-management',
    to: '/admin/literature-management',
    positionInNav: 4,
    component: ResourceManagement,
    title: 'Literature Management',
    private: true
  },
  {
    exact: true,
    icon: 'file-add',
    path: '/admin/learning-material',
    to: '/admin/learning-material',
    positionInNav: 5,
    component: LearningMaterial,
    title: 'Learning Material',
    private: true
  },
  {
    exact: true,
    icon: 'shopping',
    path: '/admin/sales',
    to: '/admin/sales',
    positionInNav: 6,
    component: Sales,
    title: 'Sales',
    private: true
  },
  {
    exact: true,
    icon: 'credit-card',
    path: '/admin/voucher-management',
    to: '/admin/voucher-management',
    positionInNav: 7,
    component: VoucherManagement,
    title: 'Voucher Management',
    private: true
  },
  {
    exact: true,
    icon: 'file',
    path: '/admin/content-management',
    to: '/admin/content-management',
    positionInNav: 8,
    component: ContentManagement,
    title: 'Content Management',
    private: true
  },
  {
    exact: true,
    icon: 'file',
    path: '/admin/faq-management',
    to: '/admin/faq-management',
    positionInNav: 9,
    component: FaqManagement,
    title: 'FAQ Management',
    private: true

  },
]

/* Routes for submenu related to each page
 *  These routes will not show the sidebar but the submenu bar
 *  If a route is a child of ADMIN or CONSUMER route please supply the same @param positionInNav
 */
const GENERAL_ROUTES = [
  // Admin submenu routes
  {
    exact: true,
    path: '/limbo',
    title: 'Loggining',
    component: Limbo
  },
  {
    exact: true,
    path: '/admin/textbook-management/new',
    to: '/admin/textbook-management/new',
    title: 'New Textbook',
    component: NewResource,
    positionInNav: 3,
    private: true
  },
  {
    exact: true,
    path: '/admin/textbook-management/:id',
    to: '/admin/textbook-management/:id',
    title: 'Edit Textbook',
    component: EditResource,
    positionInNav: 3,
    private: true
  },
  {
    exact: true,
    path: '/admin/literature-management/new',
    to: '/admin/literature-management/new',
    title: 'New Literature',
    component: NewResource,
    positionInNav: 4,
    private: true
  },
  {
    exact: true,
    path: '/admin/literature-management/:id',
    to: '/admin/literature-management/:id',
    title: 'Edit Literature',
    component: EditResource,
    positionInNav: 4,
    private: true
  },
  {
    exact: true,
    path: '/admin/voucher-management/new',
    to: '/admin/voucher-management/new',
    title: 'New Voucher',
    component: NewVoucher,
    positionInNav: 7,
    private: true
  },
  {
    exact: true,
    path: '/admin/voucher-management/:id',
    to: '/admin/voucher-management/:id',
    title: 'Edit Voucher',
    component: EditVoucher,
    positionInNav: 7,
    private: true
  },
  {
    exact: true,
    path: '/admin/content-management/new',
    to: '/admin/content-management/new',
    title: 'New Content',
    component: NewContent,
    positionInNav: 8,
    private: true
  },
  {
    exact: true,
    path: '/admin/content-management/:id',
    to: '/admin/content-management/:id',
    title: 'Edit Content',
    component: EditContent,
    positionInNav: 8,
    private: true
  },
  {
    exact: true,
    path: '/admin/faq-management/new',
    to: '/admin/faq-management/new',
    title: 'New Faq',
    component: NewFaq,
    positionInNav: 8,
    private: true
  },
  {
    exact: true,
    path: '/admin/faq-management/:id',
    to: '/admin/faq-management/:id',
    title: 'Edit Faq',
    component: EditFaq,
    positionInNav: 8,
    private: true
  },
  {
    exact: true,
    path: '/admin/user/:id',
    to: '/admin/user/:id',
    title: 'Users',
    component: EditUser,
    positionInNav: 2,
    private: true
  },
  {
    exact: true,
    path: '/admin/learning-material/:id',
    to: '/admin/learning-material/:id',
    title: 'Topic Details',
    component: TopicDetails,
    positionInNav: 5,
    private: true
  },
  {
    exact: true,
    path: '/:platform/profile',
    component: Profile,
    title: 'Profile',
    private: true
  },
  // Learner/Consumer submenu routes
  {
    exact: true,
    path: '/learner/:resourcetype/:grade',
    component: SubjectExploration,
    title: 'Grades',
    private: true
  },
  {
    exact: true,
    path: '/learner/:resourcetype/:grade/:subject',
    component: FilteredResources,
    title: 'Filtered Resources',
    private: true
  },
  {
    exact: true,
    path: '/learner/:resourcetype/:grade/:subject/:resource',
    component: ResourceDetails,
    title: 'Resource Details',
    private: true
  },
  {
    exact: true,
    path: '/learner/cart',
    component: Cart,
    title: 'Cart',
    private: true
  },
  {
    exact: true,
    path: '/learner/cart/transaction/payment/status/:transid',
    component: PaymentStatus,
    title: 'Transaction Status',
    private: true
  }
]

// Routes for front end website
const PUBLIC_ROUTES = [
  {
    exact: true,
    path: '/',
    component: Landing,
    title: 'Vivlia'
  },
  {
    exact: true,
    path: '/sign-in',
    component: Login,
    title: 'Sign In'
  },
  {
    exact: true,
    path: '/forgot-password',
    component: ForgotPassword,
    title: 'Forgot Password'
  },
  {
    exact: true,
    path: '/register',
    component: Register,
    title: 'Register'
  },
  {
    exact: true,
    path: '/textbook',
    component: ResourceExploration,
    title: 'Textbooks'
  },
  {
    exact: true,
    path: '/literature',
    component: ResourceExploration,
    title: 'Literature'
  },
  {
    exact: true,
    path: '/learning-material',
    component: ResourceExploration,
    title: 'Learning Material'
  },
  {
    exact: true,
    path: '/explore/:resourcetype/:grade',
    component: SubjectExploration,
    title: 'Grades'
  },
  {
    exact: true,
    path: '/explore/:resourcetype/:grade/:subject',
    component: FilteredResources,
    title: 'Filtered Resources'
  },
  {
    exact: true,
    path: '/explore/:resourcetype/:grade/:subject/:resource',
    component: ResourceDetails,
    title: 'Resource Details'
  },
  {
    exact: true,
    path: '/search',
    component: PublicSearch,
    title: 'Public Search'
  },
  {
    exact: true,
    path: '/faqs',
    component: ExploreFaqs,
    title: 'faqs'
  },
  {
    exact: true,
    path: '/contact-us',
    component: ContactUs,
    title: 'Contact Us'
  }
]

export { PUBLIC_ROUTES, CONSUMER_ROUTES, ADMIN_ROUTES, GENERAL_ROUTES }
