import { notification } from 'antd'
import { Storage } from 'aws-amplify'
import { DataStore } from '@aws-amplify/datastore'
import { OFFLINE_RESOURCES } from '../constants'
import localForage from 'localforage'
import { Annotation } from 'models'
import { isLoggedIn } from 'utils'

// @ts-ignore: FileSystem API is chrome-only
window.requestFileSystem = window.requestFileSystem || window.webkitRequestFileSystem

export async function download(resource: any) {
  // If the resource is an epub, get the reformatted Epub file that has a '.epub' file extension
  const resourceName = resource.layout === 'EPUB' ? `${resource.document}.epub` : resource.document

  try {
    const documentName = await Storage.get(resourceName)
      .then((url: any) => fetch(url))
      .then((res) => res.blob())
      .then((blob) => writeFile(`${resource.document}.${resource.layout.toLowerCase()}`, blob))

    const image = await Storage.get(resource.coverImage)
      .then((url: any) => fetch(url))
      .then((res) => res.blob())
      .then((blob) => writeImageFile(resource.coverImage, blob))

    // @ts-ignore: FileSystem API is chrome-only
    let resources = JSON.parse(window.localStorage.getItem(OFFLINE_RESOURCES)) || []
    resources.push({
      ...resource,
      documentName,
      imageBlob: image
    })

    window.localStorage.setItem(OFFLINE_RESOURCES, JSON.stringify(resources))
    notification.success({
      message: 'Download Successful',
      description: `${resource.title} was added to your library! You can now view this content offline.`
    })
    return resources
  } catch (error) {
    notification.error({
      message: 'Download Failed',
      description: `${resource.title} failed to download, please remove one or more items to save this file.`
    })
  }
}

async function getFile(name: string) {
  const data = await localForage.getItem(name)
  return data
}

const writeFile = (name: string, contents: Blob) =>
  new Promise((resolve, reject) => {
    try {
      localForage.setItem(name, contents).then((data) => {
        resolve(name)
      })
    } catch (error) {
      reject(error)
    }
  })

const writeImageFile = (name: string, contents: Blob) =>
  new Promise((resolve, reject) => {
    try {
      localForage.setItem(name, contents).then((data) => {
        const file = getFile(name)
        resolve(file)
      })
    } catch (error) {
      reject(error)
    }
  })

export const storeAnnotation = (annotation: any) =>
  new Promise((resolve, reject) => {
    try {
      const userInfo: any = isLoggedIn()
      const { resourceID, annotationType, selectionRange, comment, highlightColor } = annotation
      DataStore.save(
        new Annotation({
          userID: userInfo.username,
          resourceID,
          annotationType,
          selectionRange,
          comment,
          highlightColor: highlightColor ? highlightColor : ''
        })
      ).then((res) => {
        resolve(res)
      })
    } catch (error) {
      reject(error)
    }
  })

export const getAnnotations = () =>
  new Promise((resolve, reject) => {
    try {
      const userInfo: any = isLoggedIn()

      DataStore.query(Annotation, (c) => c.userID('eq', userInfo.username)).then((res) => {
        resolve(res)
      })
    } catch (error) {
      reject(error)
    }
  })

export const deleteAnnotations = (selectionRange: any, annotationType: any) =>
  new Promise((resolve, reject) => {
    try {
      const userInfo: any = isLoggedIn()

      DataStore.delete(Annotation, (c) =>
        c
          .userID('eq', userInfo.username)
          .selectionRange('eq', selectionRange)
          .annotationType('eq', annotationType)
      ).then((res) => {
        resolve(res)
      })
    } catch (error) {
      reject(error)
    }
  })
