// Development
export const IS_PROD = process.env.NODE_ENV === 'production'
// JWT
export const JWT_LOCAL_STORAGE_KEY = 'ProjectName.JWT'
// GraphQL
export const GQL_HTTP_ENDPOINT = process.env.REACT_APP_HTTP_ENDPOINT || ''
export const GQL_WS_ENDPOINT = process.env.REACT_APP_WS_ENDPOINT || ''
// Bugsnag
export const BUGSNAG_API_KEY = 'process.env.REACT_APP_BUGSNAG_API_KEY' || ''
// Google Analytics
export const GOOGLE_ANALYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID || ''
/** Cognito challenge requests */
export const COGNITO_CHALLENGES = {
  newPasswordRequired: 'NEW_PASSWORD_REQUIRED'
}

export const ADMIN_EMAIL = process.env.REACT_ADMIN_EMAIL || 'no-reply@vivlia.co.za'
export const AUTH_USER_TOKEN_KEY = 'Vivlia.TokenKey'
export const OFFLINE_RESOURCES = 'Vivlia.OFFLINE_RESOURCES'
export const CONVERTED_RESOURCES = 'Vivlia.CONVERTED_RESOURCES'
export const CONVERTED_RESOURCES_EPUB = 'Vivlia.CONVERTED_RESOURCES_EPUB'
export const FILE_DATA = 'Vivlia.FILE_DATA'
export const ALL_FILES = 'Vivlia.ALL_FILES'
export const TABLE_CONSTANTS = { limit: 1000, defaultRows: 10 }

// Peach Payments
// Header Auth provided by peach, specific to client account
export const PEACH_HEADER_AUTH = process.env.REACT_APP_PEACH_HEADER_AUTH || ''
// Live host address for payments
export const PEACH_HOST = process.env.REACT_APP_PEACH_HOST || ''
// Entity ID provided by peach
export const PEACH_ENTITY_ID = process.env.REACT_APP_PEACH_ENTITY_ID || ''

// RSA Generated for encrypting payment details
export const PEACH_ENCRYPT_KEY = `-----BEGIN PUBLIC KEY-----
MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCUAIrOHKdUBf1Cv4NrfAi1QZMY
AzFfXBuhcpqJylYkjiQzz46IFgCw8JMENbJ8VAtjHnBtEO2GamggAfal3CIvk/nI
LeE/0Pkvmz5HNKPtkqLf6O4PYTnHWh9dXqKLjMke+4/cN21Km46JKFxXiR7yVzLS
tw8e2bvE8O2IpN4GjQIDAQAB
-----END PUBLIC KEY-----`

// Dummy Dashboard Chart Data
export const DUMMYSALESDATA = [
  {
    month: 'Jan',
    sales: 0
  },
  {
    month: 'Feb',
    sales: 0
  },
  {
    month: 'Mar',
    sales: 0
  },
  {
    month: 'Apr',
    sales: 0
  },
  {
    month: 'May',
    sales: 0
  },
  {
    month: 'Jun',
    sales: 0
  },
  {
    month: 'Jul',
    sales: 0
  },
  {
    month: 'Aug',
    sales: 0
  },
  {
    month: 'Sep',
    sales: 0
  },
  {
    month: 'Oct',
    sales: 0
  },
  {
    month: 'Nov',
    sales: 0
  },
  {
    month: 'Dec',
    sales: 0
  }
]

export const DUMMYCOVERTEDUSERS = [
  {
    month: 'Jan',
    converted: 0
  },
  {
    month: 'Feb',
    converted: 0
  },
  {
    month: 'Mar',
    converted: 0
  },
  {
    month: 'Apr',
    converted: 0
  },
  {
    month: 'May',
    converted: 0
  },
  {
    month: 'Jun',
    converted: 0
  },
  {
    month: 'Jul',
    converted: 0
  },
  {
    month: 'Aug',
    converted: 0
  },
  {
    month: 'Sep',
    converted: 0
  },
  {
    month: 'Oct',
    converted: 0
  },
  {
    month: 'Nov',
    converted: 0
  },
  {
    month: 'Dec',
    converted: 0
  }
]

export const PAYMENT_TYPES = [
  { name: 'Master Card', value: 'MASTER' },
  { name: 'Visa', value: 'VISA' }
]
