import { API } from 'aws-amplify'
import { getPackageVersionNumber } from './getPackageVersionNumber'
import { isLoggedIn } from './isLoggedIn'
import { isTokenValid } from './isTokenValid'
import { logger } from './logger'
import { convertToSelector } from './convertToSelector'
import { getArrayFromObject } from './getArrayFromObject'
import { filterCaseInsensitive } from './filterCaseInsensitive'
import { formatString } from './formatString'
import { generateCode } from './generateCode'
import { textbookOrLiterature } from './textbookOrLiterature'
import { showNotification } from './notificationMessage'
import { getUserId } from './getUserId'
import { randomBackgroundColor } from './randomBackgroundColor'
import { successNotification } from './successNotification'
import { errorNotification } from './errorNotification'
import { jwtInfo } from './jwtInfo'
import { handleError } from './handleError'
import { signIn, signUp, confirmSignUp, resendConfirmSignUp } from './authHelpers'
import { setUserName } from './setUserName'
export { download } from './filesystem'
export { convert } from './convertEpub'

/**
 * Has a valid test
 * formats a string 'LIKE_THIS' to an object { value: "LIKE_THIS", label: "Like this" }
 */
const formatDropdown = (enumName: string | null | undefined | any) => {
  try {
    if (!enumName) {
      return { value: '', label: '' }
    }
    return { value: enumName, label: formatString(enumName || '') }
  } catch (error) {
    return { value: '', label: '' }
  }
}

/**
 * This function sorts an array of objects by the "createdAt" field,
 * and returns an array sorted in descending order.
 * @param toSort
 */
const sortByCreatedAt = (toSort: any[]) => {
  return toSort.sort((a: any, b: any) => (a.createdAt > b.createdAt ? 1 : -1)).reverse()
}

// get user name from local storage
const loggedInUserName = () => {
  return window.localStorage.getItem('userName')
}

// get user name & surname to return initials
const userInitials = () => {
  const name = loggedInUserName()
  const surname = window.localStorage.getItem('userSurname')
  if (name === null || surname == null) {
    return ''
  } else {
    return `${name.charAt(0)}${surname.charAt(0)}`
  }
}

/**
 *
 * Converts pixels to rems with base pixel unit of 16px
 *
 * @param  {number} unitValue - number of pixels
 * @returns string - rem value
 */
const convertPixelsToRem = (unitValue: number): string => {
  return `${(unitValue / 16).toFixed(2)}rem`
}

const sendEmail = async (params: any) => {
  let apiName = 'sendEmailApi'
  let path = '/send-email'
  await API.post(apiName, path, params)
    .then((data: any) => {
      successNotification('Email sent Successfully')
    })
    .catch((e: any) => {
      console.log('err', e)
      errorNotification('Unable to send ticket. Try again')
    })
}

export {
  logger,
  isTokenValid,
  isLoggedIn,
  getPackageVersionNumber,
  convertToSelector,
  getArrayFromObject,
  filterCaseInsensitive,
  formatDropdown,
  formatString,
  generateCode,
  sortByCreatedAt,
  textbookOrLiterature,
  showNotification,
  getUserId,
  randomBackgroundColor,
  successNotification,
  errorNotification,
  jwtInfo,
  signIn,
  signUp,
  confirmSignUp,
  resendConfirmSignUp,
  handleError,
  loggedInUserName,
  userInitials,
  convertPixelsToRem,
  sendEmail,
  setUserName
}
