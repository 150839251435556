import React, { useState, useEffect, FC, Fragment } from 'react'
import { RouteComponentProps } from 'react-router'
import { Storage } from 'aws-amplify'
import { Button, Card, Input, Modal, Select, Col, Row, Upload, Icon } from 'antd'
import { FullWidthContainer } from 'components'
import { Formik } from 'formik'
import {
  Grade,
  Language,
  LayoutType,
  Resource,
  ResourceType,
  Status,
  Subject,
  LiteratureCategory
} from 'generated/graphql'

import { formatString, generateCode, successNotification, errorNotification } from '../../utils'

type PropsType = RouteComponentProps & {
  history: History
  onSubmit: (e: any) => any
  initialValues?: Resource
  resourceType: ResourceType
}

const { Option, OptGroup } = Select
const { TextArea } = Input
const { Dragger } = Upload

const ResourceFields: FC<PropsType> = (props: PropsType) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loadingImg, setLoadingImg] = useState(true)
  const [imgUrl, setImgUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const [isLiteraturePage, setIsLiteraturePage] = useState(false)

  function handleSelect(value: any, setFieldValue: any, fieldName: any) {
    return setFieldValue(fieldName, value)
  }

  /**
   * function to covert enums to array & options for select dropdowns
   * @param enums list of enums
   */
  function selectOptions(enums: any) {
    // getting values from the enum list
    const enumsArray = Object.values(enums)
    const filteredArray = enumsArray.filter(function(item: any) {
      return item !== 'NULL'
    })
    // converts array list into select options
    const options = filteredArray.map((optVal: any, index: number) => {
      return (
        <Option key={index} value={optVal}>
          {formatString(optVal, true)}
        </Option>
      )
    })
    return options
  }

  /**
   * Confirmation modal for resource status update
   * @param status the status of the resource as per below
   */
  function setModalConfirmationMessage(status: string) {
    let text = ''
    switch (status) {
      case 'ACTIVE':
        text = 'publish'
        break
      case 'ARCHIVED':
        text = 'archive'
        break
      case 'DRAFT':
        text = 'save'
        break
      default:
        break
    }
    return `Are you sure you would like to ${text} this ${formatString(props.resourceType)}?`
  }

  /**
   * function to upload files to AWS S3 bucket, both images and resources
   * @param file the file being uploaded, image or document
   * @param setFieldValue function to update the respective field with the generated file name
   * @param fieldName name of the field in the db
   */
  async function uploadFile(file: any, setFieldValue: any, fieldName: string) {
    const fileKey = generateCode()
    return Storage.put(fileKey, file.file, {
      contentType: file.file.type
    })
      .then(() => {
        setLoading(false)
        setFieldValue(fieldName, fileKey.toString())
        successNotification('The file has been uploaded successfully')
        if (file.file.type !== 'application/pdf' || file.file.type !== 'application/epub') {
          Storage.get(fileKey, { level: 'public' }).then((result) => {
            setImgUrl(result.toString())
            setLoadingImg(false)
          })
        } else {
          return setLoading(false)
        }
      })
      .catch((err: any) => {
        setLoading(false)
        errorNotification('The file has not been uploaded, please check the file')
      })
  }

  /**
   * Replaced componentDidMount
   * Gets the image key and collects the file from AWS S3 bucket
   */
  useEffect(() => {
    setIsLiteraturePage(window.location.pathname.includes('literature'))
    if (
      props.initialValues &&
      props.initialValues.coverImage &&
      props.initialValues.coverImage !== '-'
    ) {
      Storage.get(props.initialValues.coverImage, { level: 'public' })
        .then((result) => {
          setLoadingImg(false)
          setImgUrl(result.toString())
        })
        .catch(() => {
          errorNotification('There has been an error loading your image')
        })
    } else {
      setLoadingImg(false)
    }
  }, [props.initialValues])

  /**
   * Function to delete a file from AWS S3 bucket
   * @param file file name to be deleted
   * @param setFieldValue function to update the respective field value to null or -
   * @param fieldName name of the field to update in DB
   */
  function deleteFile(file: string, setFieldValue: any, fieldName: string) {
    Storage.remove(file, { level: 'public' })
      .then(() => {
        setLoading(false)
        setFieldValue(fieldName, '-')
        successNotification('The file has been deleted successfully')
      })
      .catch(() => {
        setLoading(false)
        errorNotification('There has been an error deleting file')
      })
  }

  return (
    <Formik
      initialValues={{
        title: props.initialValues && props.initialValues.title ? props.initialValues.title : '',
        description:
          props.initialValues && props.initialValues.description
            ? props.initialValues.description
            : '',
        author: props.initialValues && props.initialValues.author ? props.initialValues.author : '',
        isbn: props.initialValues && props.initialValues.isbn ? props.initialValues.isbn : '',
        price: props.initialValues && props.initialValues.price ? props.initialValues.price : '',
        subject:
          props.initialValues && props.initialValues.subject ? props.initialValues.subject : 'NULL',
        layout: props.initialValues && props.initialValues.layout ? props.initialValues.layout : '',
        grade: props.initialValues && props.initialValues.grade ? props.initialValues.grade : '',
        category:
          props.initialValues && props.initialValues.category ? props.initialValues.category : '',
        publisher:
          props.initialValues && props.initialValues.publisher ? props.initialValues.publisher : '',
        language:
          props.initialValues && props.initialValues.language ? props.initialValues.language : '',
        status:
          props.initialValues && props.initialValues.status
            ? props.initialValues.status
            : Status.Draft,
        type: props.resourceType,
        document:
          props.initialValues && props.initialValues.document ? props.initialValues.document : '-',
        coverImage:
          props.initialValues && props.initialValues.coverImage
            ? props.initialValues.coverImage
            : '-',
        salePrice:
          props.initialValues && props.initialValues.salePrice ? props.initialValues.salePrice : '-'
      }}
      onSubmit={(values) => {
        if (values.grade === '') values.grade = 'GENERAL'
        if (values.category === '') values.category = 'CHILDREN'
        if (props.initialValues && props.initialValues.id) {
          props.onSubmit({ variables: { input: { ...values, id: props.initialValues.id } } })
        } else {
          props.onSubmit({ variables: { input: values } })
        }
      }}
    >
      {({
        handleChange,
        handleSubmit,
        isSubmitting,
        errors,
        setFieldValue,
        values: {
          title,
          description,
          author,
          publisher,
          isbn,
          price,
          subject,
          grade,
          layout,
          language,
          status,
          salePrice,
          document,
          coverImage,
          category
        }
      }) => {
        return (
          <form>
            <Modal
              visible={modalVisible}
              onOk={() => handleSubmit()}
              onCancel={() => setModalVisible(false)}
              okText="Ok"
              cancelText="Cancel"
            >
              {setModalConfirmationMessage(status)}
            </Modal>
            <Row>
              <FullWidthContainer align="left">
                <Row type="flex" justify="space-between">
                  <Col sm={24} md={6} lg={6}>
                    <Card loading={loadingImg}>
                      {coverImage && coverImage !== '-' ? (
                        <Fragment>
                          <img alt="cover" src={imgUrl} style={{ width: '100%' }} />
                          <br />
                          <br />
                          <Button
                            onClick={() => {
                              setLoading(true)
                              deleteFile(coverImage, setFieldValue, 'coverImage')
                            }}
                          >
                            <Icon type="delete" /> Remove Image
                          </Button>
                        </Fragment>
                      ) : (
                        <Dragger
                          accept="image/jpg,image/png,image/jpeg"
                          customRequest={(e: any) => {
                            setLoading(true)
                            uploadFile(e, setFieldValue, 'coverImage')
                          }}
                        >
                          <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                          </p>
                          <p className="ant-upload-text">Click to upload an image</p>
                        </Dragger>
                      )}
                    </Card>
                  </Col>
                  <Col sm={24} md={17} lg={17}>
                    <Card>
                      <Row type="flex" justify="space-between">
                        <Col sm={24} md={11} lg={11}>
                          <label>Title</label>
                          <Input
                            type="text"
                            name="title"
                            value={title}
                            id="title"
                            placeholder=""
                            onChange={handleChange}
                          />
                          <label>Description</label>
                          <TextArea
                            name="description"
                            value={description}
                            id="description"
                            placeholder=""
                            rows={3}
                            onChange={handleChange}
                          />
                          <label>Author</label>
                          <Input
                            type="text"
                            name="author"
                            value={author}
                            id="author"
                            placeholder=""
                            onChange={handleChange}
                          />
                          {props.initialValues && props.initialValues.id ? (
                            <Fragment>
                              <label>Sale Price</label>
                              <Input
                                type="text"
                                name="salePrice"
                                value={salePrice}
                                id="salePrice"
                                placeholder=""
                                onChange={handleChange}
                              />
                            </Fragment>
                          ) : null}
                        </Col>
                        <Col sm={24} md={12} lg={12}>
                          <Row type="flex" justify="space-between">
                            <Col sm={24} md={24} lg={24}>
                              {!isLiteraturePage && (
                                <Fragment>
                                  <label>Subject</label>
                                  <Select
                                    value={subject}
                                    defaultValue="LITERATURE"
                                    onChange={(value: String) =>
                                      handleSelect(value, setFieldValue, 'subject')
                                    }
                                  >
                                    {selectOptions(Subject)}
                                  </Select>
                                </Fragment>
                              )}
                            </Col>
                            <Col sm={24} md={11} lg={11}>
                              {isLiteraturePage && (
                                <div>
                                  <label>Literature Category</label>
                                  <Select
                                    value={category}
                                    onChange={(value: String) =>
                                      handleSelect(value, setFieldValue, 'category')
                                    }
                                  >
                                    {selectOptions(LiteratureCategory)}
                                  </Select>
                                </div>
                              )}
                              {!isLiteraturePage && (
                                <div>
                                  <label>Grade</label>
                                  <Select
                                    value={grade}
                                    onChange={(value: String) =>
                                      handleSelect(value, setFieldValue, 'grade')
                                    }
                                  >
                                    <OptGroup label="FOUNDATION">
                                      {selectOptions(Grade).slice(0, 4)}
                                    </OptGroup>
                                    <OptGroup label="INTERMEDIATE">
                                      {selectOptions(Grade).slice(4, 7)}
                                    </OptGroup>
                                    <OptGroup label="SENIOR">
                                      {selectOptions(Grade).slice(7, 10)}
                                    </OptGroup>
                                    <OptGroup label="FET">
                                      {selectOptions(Grade).slice(10, 13)}
                                    </OptGroup>
                                    <OptGroup label="General">
                                      {selectOptions(Grade).slice(13)}
                                    </OptGroup>
                                  </Select>
                                </div>
                              )}
                              <label>eISBN</label>
                              <Input
                                type="text"
                                name="isbn"
                                value={isbn}
                                id="isbn"
                                placeholder=""
                                onChange={handleChange}
                              />
                              <label>Language</label>
                              <Select
                                value={language}
                                onChange={(value: String) =>
                                  handleSelect(value, setFieldValue, 'language')
                                }
                              >
                                {selectOptions(Language)}
                              </Select>
                            </Col>
                            <Col sm={24} md={11} lg={11}>
                              <label>Layout type</label>
                              <Select
                                value={layout}
                                onChange={(value: String) =>
                                  handleSelect(value, setFieldValue, 'layout')
                                }
                              >
                                <Option value={LayoutType.Pdf}>
                                  {formatString(LayoutType.Pdf)}
                                </Option>
                                <Option value={LayoutType.Epub}>
                                  {formatString(LayoutType.Epub)}
                                </Option>
                              </Select>
                              <label>Publisher</label>
                              <Input
                                type="text"
                                name="publisher"
                                value={publisher}
                                id="publisher"
                                placeholder=""
                                onChange={handleChange}
                              />
                              <label>Price</label>
                              <Input
                                type="number"
                                name="price"
                                value={price}
                                id="price"
                                placeholder=""
                                onChange={handleChange}
                              />
                            </Col>
                            <Col sm={24} md={24} lg={24}>
                              <br />
                              {document && document !== '-' ? (
                                <Button
                                  onClick={() => {
                                    setLoading(true)
                                    deleteFile(document, setFieldValue, 'document')
                                  }}
                                >
                                  <Icon type="delete" /> Remove Uploaded File
                                </Button>
                              ) : (
                                <Upload
                                  accept={
                                    formatString(layout) === 'Pdf'
                                      ? 'application/pdf'
                                      : 'application/epub'
                                  }
                                  customRequest={(e: any) => {
                                    setLoading(true)
                                    uploadFile(e, setFieldValue, 'document')
                                  }}
                                >
                                  <Button size="large">
                                    <Icon type="upload" /> Click to upload{' '}
                                    {formatString(props.resourceType)}
                                  </Button>
                                </Upload>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Card>
                    <br />
                    <Card>
                      <Row>
                        <Button
                          disabled={loading}
                          onClick={() =>
                            props.history.push(
                              `/admin/${formatString(props.resourceType)}-management`
                            )
                          }
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={loading}
                          style={{ marginLeft: '10px', marginRight: '10px' }}
                          type="default"
                          onClick={() => {
                            setFieldValue('status', Status.Draft)
                            setModalVisible(true)
                          }}
                        >
                          Save
                        </Button>
                        <Button
                          disabled={loading}
                          type="default"
                          onClick={() => {
                            setFieldValue('status', Status.Active)
                            setModalVisible(true)
                          }}
                        >
                          Publish
                        </Button>
                        {props.initialValues && props.initialValues.id && (
                          <Button
                            disabled={loading}
                            style={{ marginLeft: '10px' }}
                            type="default"
                            onClick={() => {
                              setFieldValue('status', Status.Archived)
                              setModalVisible(true)
                            }}
                          >
                            Archive
                          </Button>
                        )}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </FullWidthContainer>
            </Row>
          </form>
        )
      }}
    </Formik>
  )
}

export default ResourceFields
