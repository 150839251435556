import styled from 'styled-components'
/** Themes */
import { theme } from 'theme'

type SelectedColorType = {
  background?: string | null
  small?: boolean
}

export const TableHeaderContainer = styled.div`
  margin-bottom: 40px;
  display: flex;
  flex-direction: row;
`

export const TableHeader = styled.div`
  font-size: 16px;
  margin-right: 30px;
  display: inline;
  color: ${theme.colors.textSecondary};
`

export const CircleIcon = styled.div`
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background-color: ${theme.colors.htmlBackground};
`

export const SelectedColor = styled.div<SelectedColorType>`
  width: ${(props) => (props.small ? '24px' : '38px')};
  height: ${(props) => (props.small ? '24px' : '38px')};
  border-radius: 5px;
  cursor: pointer;
  margin: 0 auto;
  background: ${(props: SelectedColorType) =>
    props.background ? props.background : theme.colors.htmlBackground};
`

export const SelectedIcon = styled.div<SelectedColorType>`
  display: flex;
  width: ${(props: { small?: boolean }) => (props.small ? '24px' : '38px')};
  height: ${(props: { small?: boolean }) => (props.small ? '24px' : '38px')};
  border-radius: 5px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  background: ${(props: SelectedColorType) =>
    props.background ? props.background : theme.colors.primary};
`

export const IconLibraryContainer = styled.div`
  & .dropdown-menu {
    min-width: 18rem;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid ${theme.colors.htmlBackground};
  }
`
