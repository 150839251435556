import React, { useState, FC, Fragment } from 'react'
import Helmet from 'react-helmet'
import { RouteComponentProps, withRouter } from 'react-router'
import { Formik } from 'formik'
import { Button, Card, Col, Input, notification, Row, Modal } from 'antd'
import { theme } from 'theme'
import { ContentContainer, ErrorMessage, Loader, SubMenu, Table } from 'components'
import images from 'theme/images'
import { GetUserComponent, UpdateUserComponent, Status } from '../../generated/graphql'
import { API } from 'aws-amplify'
import awsExports from '../../aws-exports'

type PropsType = RouteComponentProps & {
  history: History
  initialValues?: {
    name: string
    surname: string
    emailAddress: string
    contactNumber: string
    status: Status
  }
  computedMatch: { [key: string]: any }
}

const EditUser: FC<PropsType> = (props: PropsType) => {
  const [readOnly, setReadOnly] = useState(true)
  const [modalVisible, setModalVisible] = useState(false)

  function menuItems() {
    return [
      <Button key={1} type="default" onClick={() => props.history.push('/admin/users')}>
        Back to all users
      </Button>
    ]
  }

  function setModalConfirmationMessage(status: string, email: string) {
    let text = ''
    switch (status) {
      case 'ACTIVE':
        text = 'publish'
        break
      case 'ARCHIVED':
        text = 'archive'
        break
      case 'DRAFT':
        text = 'save'
        break
      default:
        break
    }
    return `Are you sure you would like to ${text} ${email}?`
  }

  function listOfPurchases(purchases: any) {
    const dummyPurchases = [{}]
    return (
      <Fragment>
        <h3>Purchases</h3>
        <Table
          data={dummyPurchases}
          columns={[
            {
              Header: 'Title',
              accessor: 'title'
            },
            {
              Header: 'Type',
              accessor: 'surname'
            },
            {
              Header: 'Purchase Date',
              accessor: 'createdAt'
            },
            {
              Header: 'Cost',
              accessor: 'cost'
            }
          ]}
          defaultSortBy={[
            {
              id: 'createdAt',
              desc: true
            }
          ]}
          defaultPageSize={5}
        />
      </Fragment>
    )
  }

  /**
   * Function to show notifications on save/update record
   * @param notif array with the details of the notifivation
   * @param notif.type success|error|warning
   * @param notif.message message to be displayed e.g User Saved
   * @param notif.description description of the event e.g the user has been successfully saved
   * */

  async function showNotification(notif: any) {
    return notification[notif.type]({
      message: notif.message,
      description: notif.description
    })
  }

  return (
    <Fragment>
      <SubMenu submenuItems={menuItems()} />
      <ContentContainer>
        <GetUserComponent variables={{ id: props.computedMatch.params.id }}>
          {({ loading, error, data }: any) => {
            if (loading) {
              return <Loader />
            }
            if (error || !data || !data.getUser) {
              return <ErrorMessage errorMessage="There has been a problem loading user's profile" />
            }
            return (
              <UpdateUserComponent
                onCompleted={() => {
                  showNotification({
                    type: 'success',
                    message: 'Updated',
                    description: 'User has been successfully updated'
                  }).then(() => {
                    setModalVisible(false)
                  })
                }}
                onError={() => {
                  showNotification({
                    type: 'error',
                    message: 'Update Error',
                    description: 'There has been an error updating the user'
                  })
                }}
              >
                {(updateUser, { error }) => (
                  <Formik
                    initialValues={{
                      name:
                        props.initialValues && props.initialValues.name
                          ? props.initialValues.name
                          : data.getUser.name,
                      surname:
                        props.initialValues && props.initialValues.surname
                          ? props.initialValues.surname
                          : data.getUser.surname,
                      emailAddress:
                        props.initialValues && props.initialValues.emailAddress
                          ? props.initialValues.emailAddress
                          : data.getUser.emailAddress,
                      contactNumber:
                        props.initialValues && props.initialValues.contactNumber
                          ? props.initialValues.contactNumber
                          : data.getUser.contactNumber,
                      status:
                        props.initialValues && props.initialValues.status
                          ? props.initialValues.status
                          : Status.Active
                    }}
                    onSubmit={(values) => {
                      const params = {
                        body: {
                          userPoolId: awsExports.aws_user_pools_id,
                          userName: props.computedMatch.params.id
                        }
                      }
                      API.post('disableUserAccount', '/disable-user', params)
                        .then(() => {
                          updateUser({
                            variables: { input: { ...values, id: props.computedMatch.params.id } }
                          })
                        })
                        .catch((err: any) => {
                          showNotification({
                            type: 'error',
                            message: 'Update Error',
                            description: 'There has been an error updating the user',
                            err
                          })
                        })
                    }}
                  >
                    {({
                      handleChange,
                      handleSubmit,
                      errors,
                      setFieldValue,
                      values: { name, surname, emailAddress, contactNumber, status }
                    }) => {
                      return (
                        <form>
                          <Modal
                            visible={modalVisible}
                            onOk={() => handleSubmit()}
                            onCancel={() => setModalVisible(false)}
                            okText="Ok"
                            cancelText="Cancel"
                          >
                            {setModalConfirmationMessage(status, emailAddress)}
                          </Modal>
                          <Row type="flex" justify="space-between">
                            <Col span={7}>
                              <Card>
                                <img
                                  alt="profile"
                                  style={{ width: '100%', height: 'auto' }}
                                  src={images.fet}
                                />
                                <br />
                                <h4>Olisha Maneerattana</h4>
                              </Card>
                            </Col>
                            <Col span={16}>
                              <Card>
                                <h5>Personal Information</h5>
                                <Row type="flex" justify="space-between">
                                  <Col span={11}>
                                    <label>Name</label>
                                    <Input
                                      type="text"
                                      placeholder="Olishia"
                                      name="name"
                                      value={name}
                                      onChange={handleChange}
                                      readOnly={readOnly}
                                    />
                                    {errors.name}
                                    <label>Surname</label>
                                    <Input
                                      type="text"
                                      placeholder="Maneerattana"
                                      name="lastName"
                                      value={surname}
                                      onChange={handleChange}
                                      readOnly={readOnly}
                                    />
                                    {errors.surname}
                                    <label>Email</label>
                                    <Input
                                      type="email"
                                      placeholder="olisha@vivlia.co.za"
                                      name="emailAddress"
                                      readOnly={true}
                                      value={emailAddress}
                                      onChange={handleChange}
                                    />
                                    {errors.emailAddress}
                                    <label>Phone Number</label>
                                    <Input
                                      type="number"
                                      placeholder="+27711754693"
                                      name="contactNumber"
                                      value={contactNumber}
                                      onChange={handleChange}
                                      readOnly={readOnly}
                                    />
                                    {errors.contactNumber}
                                  </Col>
                                </Row>
                              </Card>
                              <br />
                              <Row type="flex" justify="space-around">
                                <Button
                                  type="primary"
                                  onClick={() => {
                                    setReadOnly(true)
                                  }}
                                  color={theme.colors.textPrimary}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  disabled={status === Status.Archived}
                                  onClick={() => {
                                    setFieldValue('status', Status.Archived)
                                    setModalVisible(true)
                                  }}
                                >
                                  {status === Status.Archived ? 'Archived' : 'Archive'}
                                </Button>
                                <Button
                                  htmlType="submit"
                                  type="default"
                                  color={theme.colors.textPrimary}
                                >
                                  Update
                                </Button>
                              </Row>
                            </Col>
                          </Row>
                          <br />
                          <br />
                          <Row>{listOfPurchases(data)}</Row>
                        </form>
                      )
                    }}
                  </Formik>
                )}
              </UpdateUserComponent>
            )
          }}
        </GetUserComponent>
      </ContentContainer>
      <Helmet title="Profile" />
    </Fragment>
  )
}

export default withRouter(EditUser)
