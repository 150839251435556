import client from 'apollo'
import { GetUserDocument } from 'generated/graphql'
import { getUserId, errorNotification } from 'utils'

export const setUserName = async (props: any) => {
  return await client
    .query({
      query: GetUserDocument,
      fetchPolicy: 'network-only',
      variables: {
        id: getUserId()
      }
    })
    .then((data: any) => {
      if (data.data.getUser === null) {
        errorNotification('An error occurred when creating your account. Please contact the Admin')
      }
      if (data.data.getUser.name === null || data.data.getUser.surname === null) {
        props.history.push('/learner/profile')
      }
      window.localStorage.setItem('userName', data.data.getUser.name)
      window.localStorage.setItem('userSurname', data.data.getUser.surname)
    })
}
