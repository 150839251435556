import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'

interface IProps {
  children: ReactNode
}

const PanelWrapper = styled.div`
  position: fixed;
  left: 0;
  width: 450px;
  top: 0;
  bottom: 0;
  display: flex;
  z-index: 1;
  flex-direction: column;
  max-width: 100%;
  padding: 3em;
  overflow-y: auto;
  background: #ffffff;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  box-shadow: 11px 0 16px -8px rgba(0, 0, 0, 0.4), -12px 0 8px -4px rgba(0, 0, 0, 0.2);
`

const LoginPanel: FC<IProps> = (props: IProps) => {
  return <PanelWrapper>{props.children}</PanelWrapper>
}

export default LoginPanel
