import React, { FC, Fragment } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { Button } from 'antd'
import { UpdateResourceComponent } from 'generated/graphql'
import { ContentContainer, Loader, ContentFields, SubMenu } from '../../components'
import { GetResourceComponent } from '../../generated/graphql'

type PropsType = RouteComponentProps & {
  history: History
  location?: { [key: string]: any }
  computedMatch?: { [key: string]: any }
}

const EditContent: FC<PropsType> = (props: PropsType) => {
  function menuItems() {
    return [
      <Button key={1} onClick={() => props.history.push(`/admin/content-management`)}>
        Back to all content
      </Button>
    ]
  }
  return (
    <GetResourceComponent
      // @ts-ignore
      variables={{ id: props.computedMatch.params.id }}
    >
      {({ loading, error, data }: any) => {
        if (error) {
          return 'there was a problem loading your data'
        }
        if (loading) {
          return <Loader />
        }
        if (data && data.getResource) {
          return (
            <Fragment>
              <SubMenu submenuItems={menuItems()} />
              <ContentContainer>
                <UpdateResourceComponent
                  onCompleted={() => {
                    props.history.push(`/admin/content-management`)
                  }}
                >
                  {(updateResource, { error }) => (
                    <ContentFields
                      {...props}
                      onSubmit={updateResource}
                      initialValues={data.getResource}
                    />
                  )}
                </UpdateResourceComponent>
              </ContentContainer>
            </Fragment>
          )
        } else {
          return
        }
      }}
    </GetResourceComponent>
  )
}

export default withRouter(EditContent)
