import jwtDecode from 'jwt-decode'
import { AUTH_USER_TOKEN_KEY } from '../constants'

/**
 * Return user id from JWT
 */
export const getUserId = () => {
  try {
    const token = window.localStorage.getItem(AUTH_USER_TOKEN_KEY)
    if (token) {
      // @ts-ignore after react scripts changes
      const decodedJwt: any = jwtDecode(token)
      return decodedJwt.sub
    }
    return ''
  } catch (error) {
    return null
  }
}
