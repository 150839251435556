import { AUTH_USER_TOKEN_KEY } from '../constants'
import jwtDecode from 'jwt-decode'

export function isLoggedIn(): string {
  const token = window.localStorage.getItem(AUTH_USER_TOKEN_KEY)
  if (token) {
    const userInfo: any = jwtDecode(token)
    return userInfo
  }
  return ''
}
