import React, { FC, CSSProperties } from 'react'
import styled from 'styled-components'
// Themes
import { theme } from 'theme'

type IProps = {
  text: any
  color?: string
  pointer?: boolean
  size?: string
  style?: CSSProperties
  onClick?: () => void
}

type ISpan = {
  color?: string
  pointer?: boolean
  size?: string
  style?: CSSProperties
}

const Span = styled.span`
  color: ${(props: ISpan) => (props.color ? props.color : `${theme.colors.transparentBlack}`)};
  cursor: ${(props: ISpan) => (props.pointer ? 'pointer' : 'auto')};
  font-size: ${(props: ISpan) => (props.size ? `${props.size}` : '12px')};
`

const SpanWrapper: FC<IProps> = (props: IProps) => {
  return (
    <Span
      color={props.color}
      size={props.size}
      pointer={props.pointer}
      style={props.style}
      onClick={props.onClick}
    >
      {props.text}
    </Span>
  )
}

export default SpanWrapper
