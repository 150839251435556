import React, { FC } from 'react'
import { Button, Input, Col, Row, Select } from 'antd'
import { Formik } from 'formik'
import { API } from 'aws-amplify'
import { FullWidthContainer } from 'components'
import { UserRole, CreateUserComponent } from 'generated/graphql'
import { formatString, successNotification, errorNotification } from 'utils'
import awsExports from '../../aws-exports.js'

type Props = {
  closeModal: () => void
  refetch: () => void
  loadingBtn: boolean
  toggleLoading: () => void
}

type ValuesType = {
  name: string
  surname: string
  emailAddress: string
  role: UserRole
}

const { Option } = Select

async function createUserCD(values: ValuesType) {
  let apiName = 'createAdminUser'
  let path = '/create-user'
  let myInit = {
    body: {
      userPoolId: awsExports.aws_user_pools_id,
      userName: values.emailAddress,
      groupName: values.role
    }
  }
  return await API.post(apiName, path, myInit)
}

const newUser: FC<Props> = ({ closeModal, refetch, loadingBtn, toggleLoading }: Props) => {
  function handleSelect(value: any, setFieldValue: any, fieldName: any) {
    return setFieldValue(fieldName, value)
  }

  return (
    <CreateUserComponent>
      {(createUser, { error }) => (
        <Formik
          initialValues={{
            name: '',
            surname: '',
            emailAddress: '',
            role: UserRole.Admin
          }}
          onSubmit={(values) => {
            toggleLoading()
            createUserCD(values)
              .then((data: any) => {
                createUser({
                  variables: {
                    input: {
                      id: data.User.Username,
                      name: values.name,
                      surname: values.surname,
                      role: values.role,
                      emailAddress: values.emailAddress
                    }
                  }
                }).then(() => {
                  refetch()
                  closeModal()
                  toggleLoading()
                  successNotification(`${values.name} has been successfully added`)
                })
                toggleLoading()
              })
              .catch((e) => {
                toggleLoading()
                errorNotification(e.message)
              })
          }}
        >
          {({
            handleChange,
            handleSubmit,
            setFieldValue,
            values: { name, surname, emailAddress, role }
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Row>
                  <FullWidthContainer align="left">
                    <label>Name</label>
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      id="name"
                      placeholder=""
                      onChange={handleChange}
                      required
                    />
                    <label>Surname</label>
                    <Input
                      type="text"
                      name="surname"
                      value={surname}
                      id="surname"
                      placeholder=""
                      onChange={handleChange}
                      required
                    />
                    <label>Email</label>
                    <Input
                      type="email"
                      name="emailAddress"
                      value={emailAddress}
                      id={emailAddress}
                      placeholder=""
                      onChange={handleChange}
                    />
                    <label>User Role</label>
                    <Select
                      defaultValue={UserRole.Admin}
                      onChange={(value: String) => handleSelect(value, setFieldValue, 'role')}
                    >
                      <Option value={UserRole.Admin}>{formatString(UserRole.Admin)}</Option>
                      <Option value={UserRole.Agent}>{formatString(UserRole.Agent)}</Option>
                      <Option value={UserRole.SuperAdmin}>
                        {formatString(UserRole.SuperAdmin)}
                      </Option>
                    </Select>
                    <br />
                    <br />
                  </FullWidthContainer>
                  <FullWidthContainer align="center">
                    <Col span={24}>
                      <Button
                        loading={loadingBtn}
                        disabled={loadingBtn}
                        type="primary"
                        htmlType="submit"
                      >
                        Invite
                      </Button>
                    </Col>
                  </FullWidthContainer>
                </Row>
              </form>
            )
          }}
        </Formik>
      )}
    </CreateUserComponent>
  )
}

export default newUser
