import React, { FC } from 'react'
import styled from 'styled-components'
import { theme } from 'theme'

type IButton = {
  open: boolean
}

type IProps = {
  open: boolean
  onClick(): void
}

const Button = styled.div`
  width: 2.25em;
  height: 2em;
  background: 'transparent';
  display: flex;
  position: relative;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 20px;
  & span {
    width: 70%;
    height: 2px;
    background-color: ${theme.colors.textSecondary};
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform-origin: center left;
    border-radius: 5px;
    &:nth-child(1) {
      transform: ${(props: IButton) =>
        !props.open
          ? 'translateY(-0.12em) translateX(0.8em) rotate(45deg) scaleX(0.6)'
          : 'translate3d(0,0,0) rotate(0deg)'};
    }
    &:nth-child(2) {
      margin: 5px 0;
    }
    &:nth-child(3) {
      transform: ${(props: IButton) =>
        !props.open
          ? 'translateY(0.12em) translateX(0.8em) rotate(-45deg) scaleX(0.6)'
          : 'translate3d(0,0,0) rotate(0deg)'};
    }
  }
  &:hover {
    cursor: pointer;
  }
  &:hover span {
    transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    background-color: ${theme.colors.textSecondary};
    &:nth-child(1) {
      transform: ${(props: IButton) =>
        !props.open
          ? 'translateY(-0.12em) translateX(0.8em) rotate(45deg) scaleX(0.6)'
          : 'scaleX(1.1)'};
    }
    &:nth-child(2) {
      ${(props: IButton) => props.open && 'transform: scaleX(0.8)'};
    }
    &:nth-child(3) {
      transform: ${(props: IButton) =>
        !props.open
          ? 'translateY(0.12em) translateX(0.8em) rotate(-45deg) scaleX(0.6)'
          : 'scaleX(0.95)'};
    }
  }
`

const HamburgerIcon: FC<IProps> = ({ open, onClick }) => {
  return (
    <Button open={open} onClick={onClick}>
      <span />
      <span />
      <span />
    </Button>
  )
}

export default HamburgerIcon
