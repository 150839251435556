import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import Amplify from 'aws-amplify'
import RootContainer from 'containers/RootContainer'
import { AppProvider } from 'contexts'
import React, { FC } from 'react'
import { ApolloProvider } from 'react-apollo'
import axe from 'react-axe'
import * as ReactDOM from 'react-dom'
import client from './apollo'
import awsExports from './aws-exports.js'
import { ErrorBoundaryFallback } from './components'
import { BUGSNAG_API_KEY } from './constants'
import './index.css'
import registerServiceWorker from './serviceWorker'
import { logger } from './utils'

/**
 * Bugsnag setup
 */
const bugsnagClient = bugsnag(BUGSNAG_API_KEY)
bugsnagClient.use(bugsnagReact, React)
const ErrorBoundary = bugsnagClient.getPlugin('react')

/**
 * Amplify setup - for login only
 * TODO move to a lambda
 */
Amplify.configure(awsExports)

/**
 * Setup React Axe for accessibility
 */
if (process.env.NODE_ENV !== 'production') {
  axe(React as any, ReactDOM, 1000)
}

const Root: FC<{}> = () => (
  <div className="App">
    <ErrorBoundary FallbackComponent={ErrorBoundaryFallback}>
      <ApolloProvider client={client}>
        <AppProvider>
          <RootContainer />
        </AppProvider>
      </ApolloProvider>
    </ErrorBoundary>
  </div>
)

ReactDOM.render(<Root />, document.getElementById('root') as HTMLElement)
registerServiceWorker(() => logger('SW update required'))
