import React, { FC, Fragment } from 'react'
import './index.css'
import images from 'theme/images'
import { savePDF } from '@progress/kendo-react-pdf'
import PdfContainer from './pdfContainer'

type Props = {
  invData: any
}

const Invoice: FC<Props> = ({ invData }: Props) => {
  function pdfDoc(html: any) {
    return savePDF(html, {
      paperSize: 'Letter',
      fileName: `${invData.inv.tempTransId}.pdf`,
      margin: 3
    })
  }

  function tableItems() {
    const items = invData.inv.resource.items.map((item: any, index: number) => {
      return (
        <tr key={index} className="item">
          <td>{item.resource.title}</td>
          <td>R {item.resource.price}</td>
        </tr>
      )
    })
    return items
  }

  return (
    <Fragment>
      <PdfContainer createPdf={(e: any) => pdfDoc(e)}>
        <div className="invoice-box">
          <table cellPadding="0" cellSpacing="0">
            <tbody>
              <tr className="top">
                <td colSpan={2}>
                  <table>
                    <tbody>
                      <tr>
                        <td className="title">
                          <img alt="logo" src={images.vivliaLogo} style={{ maxWidth: '74px' }} />
                        </td>
                        <td>
                          Invoice #: {invData.inv.tempTransId}
                          <br />
                          Created: {new Date(invData.inv.createdAt).toLocaleDateString()}
                          <br />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr className="information">
                <td colSpan={2}>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          Vivlia Publishers
                          <br />
                          22 Minerva Avenue, Lea Glen, Florida
                          <br />
                          Gauteng South Africa
                        </td>
                        <td>
                          {invData.user.name} {invData.user.surname}
                          <br />
                          {invData.user.contactNumber}
                          <br />
                          {invData.user.emailAddress}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr className="heading">
                <td>Item</td>
                <td>Price</td>
              </tr>
              {tableItems()}
              <tr className="total">
                <td></td>
                <td>Total: R {invData.inv.cost}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </PdfContainer>
    </Fragment>
  )
}

export default Invoice
