import React, { FC } from 'react'
import styled from 'styled-components'
// Custom types
import { Error } from '../../constants/customTypes'

type ErrorMessageProps = {
  errorMessage: Error
}

const ErrorMessage = styled.p`
  text-align: center;
  margin-top: 10px;
  color: red;
`

const ErrorMessageContainer: FC<ErrorMessageProps> = (props: ErrorMessageProps) => {
  return <ErrorMessage>{props.errorMessage}</ErrorMessage>
}

export default ErrorMessageContainer
