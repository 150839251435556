import React, { useState, FC } from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
/** Styled elements */
import { Row, Button, Modal, Input, Col, Tag } from 'antd'
import { ApolloConsumer } from 'react-apollo'
import {
  Status,
  CreateTransactionDocument,
  TransactionStatus,
  CreateTransactionResourceConnDocument,
  ListVoucherWResourcesComponent
} from 'generated/graphql'
import {
  errorNotification,
  formatString,
  getUserId,
  successNotification,
  isLoggedIn,
  convertPixelsToRem
} from 'utils'
import { InputType } from 'constants/customTypes'
import { ErrorMessage } from 'components'
import { PageHeader } from 'components'
import StyledButton from '../../components/Styled/Button'
import { theme } from 'theme'
import client from 'apollo'

type Props = RouteComponentProps & {
  submenuItems?: Array<any>
  redeemVoucherBtn?: boolean | false
}

const SubMenu: FC<Props> = (props: Props) => {
  const { submenuItems, redeemVoucherBtn } = props
  const [voucherCode, setVoucherCode] = useState('')
  const [voucherModal, toggleVoucherModal] = useState(false)
  const [loadingBtn, toggleLoading] = useState(false)
  const [voucherEMsg, setVoucherEMsg] = useState('')
  const [voucherContentsModal, toggleVoucherContentsModal] = useState(false)
  const [voucherContentsList, setVoucherContentsList] = useState({})
  const [voucherId, setVoucherId] = useState('')
  const [resources, setResources] = useState([])

  // Function to redeem voucher from anywhere in the learner platform
  function redeemVoucher({ loading, error, data }: any) {
    const voucherId =
      data.listVouchers && data.listVouchers.items[0] && data.listVouchers.items[0].id
    if (!loading && error) {
      setVoucherEMsg('Failed to fetch voucher')
    }
    return (
      <Button
        loading={loading}
        disabled={!voucherId}
        onClick={() => {
          setVoucherEMsg('')
          if (
            data.listVouchers.items[0].maxUses <=
            data.listVouchers.items[0].transactions.items.length
          ) {
            errorNotification('This voucher has already been used')
          } else {
            setVoucherId(data.listVouchers.items[0].id)
            setResources(data.listVouchers.items[0].resources.items)
            checkVoucherValidity(data)
            setVoucherCode('')
          }
        }}
      >
        {voucherCode && !voucherId ? 'Invalid Voucher' : 'Redeem Voucher'}
      </Button>
    )
  }

  /**
   * Function to check whether the voucher is still valid
   * @param voucher
   * @param createResourceUsers gql mutation to link user & resource
   */
  function checkVoucherValidity(voucher: any) {
    if (voucher.listVouchers.items[0].id) {
      toggleVoucherModal(false)
      return resourcesList(voucher.listVouchers.items[0])
    } else {
      return errorNotification('Your voucher is invalid')
    }
  }

  /**
   * function to show list of resources in voucher
   * @param voucher the details of the voucher incl. the resources
   */
  function resourcesList(voucher: any) {
    const items = voucher.resources.items.map((resource: any) => {
      return (
        <Col key={resource.id}>
          <Tag>
            {formatString(resource.resource.type)} : {resource.resource.title} -{' '}
            {formatString(resource.resource.grade)} - {formatString(resource.resource.subject)}
          </Tag>
          <br />
          <br />
        </Col>
      )
    })
    setVoucherContentsList(items)
    return toggleVoucherContentsModal(true)
  }

  // function to create a transaction record with details of the voucher & user
  function claimContents() {
    return (
      <ApolloConsumer>
        {(client) => (
          <Button
            loading={loadingBtn}
            disabled={loadingBtn}
            onClick={async () => {
              toggleLoading(true)
              return await client
                .mutate({
                  mutation: CreateTransactionDocument,
                  variables: {
                    input: {
                      status: TransactionStatus.Complete,
                      transactionVoucherId: voucherId,
                      transactionUserId: getUserId()
                    }
                  }
                })
                .then((data) => {
                  if (data && data !== null) {
                    return addToLibrary(data.data.createTransaction.id)
                  } else {
                    return errorNotification('There has been an error')
                  }
                })
                .catch(() => {
                  return errorNotification('There has been an error adding your items to library')
                })
            }}
          >
            Confirm
          </Button>
        )}
      </ApolloConsumer>
    )
  }

  /**
   * function to add resources to users library
   * @param transId id of the initial transaction
   */
  function addToLibrary(transId: string) {
    resources.map((res: any) => {
      return client.mutate({
        mutation: CreateTransactionResourceConnDocument,
        variables: {
          input: {
            transactionResourceConnTransactionId: transId,
            transactionResourceConnResourceId: res.resource.id
          }
        }
      })
    })
    toggleVoucherContentsModal(false)
    toggleLoading(false)
    successNotification('Resources have been added to your library')
    return props.history.push('/learner/your-library')
  }

  return (
    <PageHeader>
      <Modal
        title="Redeem Voucher"
        visible={voucherModal}
        onCancel={() => toggleVoucherModal(false)}
        footer={[]}
      >
        <label>Enter Voucher</label>
        <Input
          type="text"
          name="voucherCode"
          id="voucherCode"
          value={voucherCode}
          onChange={(e: InputType) => setVoucherCode(e.currentTarget.value)}
        />
        <br />
        <ErrorMessage errorMessage={voucherEMsg} />
        <br />
        <ListVoucherWResourcesComponent
          fetchPolicy="network-only"
          variables={{
            filter: { status: { eq: Status.Active }, code: { eq: voucherCode || null } },
            limit: 100
          }}
        >
          {({ loading, error, data }: any) => {
            return redeemVoucher({ loading, error, data })
          }}
        </ListVoucherWResourcesComponent>
      </Modal>
      <Modal
        title="Voucher Content"
        visible={voucherContentsModal}
        onCancel={() => toggleVoucherContentsModal(false)}
        footer={[]}
      >
        <p>The following items will be added to your library:</p>
        {voucherContentsList}
        {claimContents()}
      </Modal>
      <Row type="flex" justify="end">
        {redeemVoucherBtn
          ? [
              submenuItems,
              <StyledButton
                type="default"
                label="Redeem Voucher"
                color={theme.colors.textSecondary}
                background={theme.colors.primary}
                margin={`0 ${convertPixelsToRem(80)} 0 ${convertPixelsToRem(560)}`}
                key={10}
                onClick={() => {
                  if (isLoggedIn()) {
                    toggleVoucherModal(true)
                  } else {
                    errorNotification('Please login or register to redeem a voucher')
                  }
                }}
                align="left"
              />
            ]
          : submenuItems}
      </Row>
    </PageHeader>
  )
}

export default withRouter(SubMenu)
