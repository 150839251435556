import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'

type ContextType = {
  authenticating: boolean
  isAuthenticated: boolean
  setIsAuthenticated: Dispatch<SetStateAction<boolean>>
  // TODO: Set proper type for cognitoUser
  cognitoUser?: any
}

const DEFAULT_STATE = {
  authenticating: true,
  isAuthenticated: false,
  setIsAuthenticated: () => false
}

export const AuthenticationContext = React.createContext<ContextType>(DEFAULT_STATE)

const Provider: FC = ({ children }) => {
  const [authenticating, setAuthenticating] = useState(DEFAULT_STATE.authenticating)
  const [isAuthenticated, setIsAuthenticated] = useState(DEFAULT_STATE.isAuthenticated)
  const [cognitoUser, setCognitoUser] = useState({})

  function checkAuthStatus() {
    setAuthenticating(true)
    Auth.currentAuthenticatedUser()
      .then((result) => {
        setCognitoUser(result)
        setIsAuthenticated(true)
        setAuthenticating(false)
      })
      .catch(() => {
        setIsAuthenticated(false)
        setAuthenticating(false)
      })
  }

  // FIX: Apparently this implementation is not best practice, this is needed to
  // re-authenticate a user on page load.
  useEffect(() => {
    checkAuthStatus()
  }, [])

  return (
    <AuthenticationContext.Provider
      value={{ authenticating, cognitoUser, isAuthenticated, setIsAuthenticated }}
    >
      {children}
    </AuthenticationContext.Provider>
  )
}

export default Provider
