import React, { useState, useEffect, FC, ReactNode } from 'react'
import { withRouter } from 'react-router-dom'
import { RouteComponentProps } from 'react-router'
// Custom types
import { NavItemType } from '../../constants/customTypes'
import { Logo, LogoCont } from './StyledElements'
// CSS styling
import './index.css'
import { Layout, Menu, Icon, Dropdown, Avatar, Row, Col, Button } from 'antd'
import images from 'theme/images'
import HamburgerIcon from './HamburgerIcon'
import { theme } from 'theme'
import { setUserName, userInitials } from 'utils'

type PropsType = RouteComponentProps & {
  render: ReactNode
  title: string
  navItems: NavItemType[]
  path: string
  role: string
  icon: string
  userId: string
  positionInNav: string
  computedMatch?: { [key: string]: any }
}

const Sidebar: FC<PropsType> = (props: PropsType) => {
  // return sidebar nav items
  function returnNavItems() {
    const { navItems, role } = props
    const { SubMenu } = Menu
    const navItemsToDisplay = navItems.filter((navItem) => navItem.role === role)

    return navItemsToDisplay.map((item, i) => {
      return item.subMenu ? (
        <SubMenu
          key={i + 1}
          title={
            <div>
              <Icon type={`${item.icon}`} />
              <span>{item.title}</span>
            </div>
          }
        >
          {item.subMenu.map((sub, j) => {
            return (
              <Menu.Item key={'sub' + j + i} onClick={() => props.history.push(sub.to)}>
                <span>{sub.title}</span>
              </Menu.Item>
            )
          })}
        </SubMenu>
      ) : (
        <Menu.Item key={i + 1} onClick={() => props.history.push(item.to)}>
          <Icon type={`${item.icon}`} />
          <span>{item.title}</span>
        </Menu.Item>
      )
    })
  }
  const { Sider, Header } = Layout

  const [menuToggle, setMenuToggle] = useState(false)
  function whereAmI() {
    const currentUrl = props.location.pathname.split('/')
    if (props.computedMatch && props.computedMatch.params && props.computedMatch.params.id) {
      const idInArray = currentUrl.indexOf(props.computedMatch.params.id)
      if (~idInArray) {
        currentUrl[idInArray] = 'Edit'
      }
    }
    if (props.computedMatch && props.computedMatch.params && props.computedMatch.params.resource) {
      const isResourcePage = currentUrl.indexOf(props.computedMatch.params.resource)
      if (~isResourcePage) {
        currentUrl[isResourcePage] = 'Details'
      }
    }
    const breadcrubms = currentUrl
      .map((l) => l.charAt(0).toUpperCase() + l.substring(1))
      .join(' > ')
      .replace('>', '')
      .replace(/-/g, ' ')
    return breadcrubms
  }

  // Logged in user dropdown menu
  function userMenu() {
    // check current url to direct user to the correct profile page
    const platform = props.location.pathname.includes('/admin/')
      ? '/admin/profile'
      : '/learner/profile'

    return (
      <Menu>
        <Menu.Item>
          <a rel="noopener noreferrer" href={platform}>
            Profile
          </a>
        </Menu.Item>
        <Menu.Item>
          <p onClick={() => signOut()}>Logout</p>
        </Menu.Item>
      </Menu>
    )
  }

  // Clear store & signout the user
  function signOut() {
    window.localStorage.clear()
    props.history.push('/sign-in')
  }

  // Check whether we saved user name & surname locally
  useEffect(() => {
    const userDetails = window.localStorage.getItem('userName')
    if (userDetails === null) {
      setUserName(props)
    } else {
    }
  })

  return (
    <Layout style={{ height: '-webkit-fill-available' }}>
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        onBreakpoint={(broken: boolean) => {
          console.log('TCL: onBreakpoint', broken)
        }}
        onCollapse={(broken: any, type: any) => {
          console.log('TCL: onCollapse', broken, menuToggle)
          setMenuToggle(!menuToggle)
        }}
        trigger={!menuToggle ? null : ''}
        collapsible
        collapsed={menuToggle}
        width="250"
      >
        <LogoCont open={!menuToggle}>
          <HamburgerIcon onClick={() => setMenuToggle(!menuToggle)} open={!menuToggle} />
          <Logo open={menuToggle} src={images.vivliaLogo} />
        </LogoCont>
        <Menu
          theme="dark"
          mode="inline"
          style={{ textAlign: 'left' }}
          defaultSelectedKeys={[`${props.positionInNav}`]}
        >
          {returnNavItems()}
        </Menu>
      </Sider>
      <Layout>
        <Header style={{ background: '#fff', textAlign: 'left' }}>
          <Row type="flex" justify="space-between">
            <Col id="breadCrumbs" span={14} style={{ zIndex: 0 }}>
              <h2>{whereAmI()}</h2>
            </Col>
            <Col id="cartProfile" span={10}>
              <Row gutter={24} type="flex" justify="end">
                {props.location.pathname.includes('/learner/') ? (
                  <Col id="cartButton" span={12}>
                    <Button onClick={() => props.history.push('/learner/cart')}>
                      <Icon type="shopping-cart" style={{ fontSize: '16px' }} /> View Cart
                    </Button>
                  </Col>
                ) : (
                  ''
                )}
                <Col span={12}>
                  <h2>
                    <Dropdown overlay={userMenu()} placement="bottomRight">
                      <Avatar style={{ backgroundColor: theme.colors.primary, marginLeft: '90%' }}>
                        {userInitials()}
                      </Avatar>
                    </Dropdown>
                  </h2>
                </Col>
              </Row>
            </Col>
          </Row>
        </Header>
        <div style={{ zIndex: 0 }}>{props.render}</div>
      </Layout>
    </Layout>
  )
}

export default withRouter(Sidebar)
