import { Button, Col, Row, Tabs } from 'antd'
import React, { FC, Fragment } from 'react'
// ui components
import { FullWidthContainer } from '..'
import { Resource, Unit } from 'generated/graphql'
import { Collapse } from 'antd'
import { sortByCreatedAt } from 'utils'

const { Panel } = Collapse

type PropsType = {
  purchased?: boolean
  addToCart: (id: string) => void
  data: Resource
  loadingImg: boolean
  loadingButton: boolean
  imgUrl: string
}

const { TabPane } = Tabs

const LearnerLearningMaterialDetailsFields: FC<PropsType> = ({
  addToCart,
  data,
  loadingImg,
  imgUrl,
  loadingButton,
  purchased
}: PropsType) => {
  function renderUnitsList() {
    if (data && data.units && data.units.items && data.units.items.length > 0) {
      return (
        <Collapse accordion>
          {sortByCreatedAt(data.units.items).map((unit: Unit, key) => {
            const { name, description, keywords, objectives } = unit
            return (
              <Panel header={name} key={key}>
                <h4>Description</h4>
                <p>{description}</p>
                <h4>Keywords</h4>
                <p>{keywords}</p>
                <h4>Objectives</h4>
                <p>{objectives}</p>
              </Panel>
            )
          })}
        </Collapse>
      )
    } else {
      return <p>No units on this module.</p>
    }
  }

  return (
    <Fragment>
      <Row gutter={24}>
        <FullWidthContainer align="left">
          <Col sm={24} md={24} lg={6}>
            <h2>{data.title}</h2>
            <p>{data.description}</p>
            <h2>R {data.price} </h2>
            {purchased ? null : (
              <Button
                loading={loadingButton}
                disabled={loadingButton}
                icon="shopping-cart"
                onClick={() => addToCart(data.id)}
              >
                Add to cart
              </Button>
            )}
          </Col>
          <Col sm={24} md={24} lg={18}>
            <Tabs defaultActiveKey="1">
              <TabPane tab="Units" key="1">
                {renderUnitsList()}
              </TabPane>
              <TabPane tab="How to Access" key="2">
                <p>After successful purchase this item will be added to your library</p>
                <p>You can access your library in the following ways:</p>
                <p>From computer, you can access your library after successful login</p>
                <p>From Android App,you can download the app from here</p>
                <p>From IOS App,you can download the app from here</p>
                <p>
                  For other devices, you can access your library using this web app through a
                  browser on your phone
                </p>
              </TabPane>
            </Tabs>
          </Col>
        </FullWidthContainer>
      </Row>
    </Fragment>
  )
}

export default LearnerLearningMaterialDetailsFields
