import React, { useState, useEffect, FC } from 'react'
import ReactGA from 'react-ga'
import { Route } from 'react-router-dom'
import { GOOGLE_ANALYTICS_TRACKING_ID } from '../constants'

type PropsType = {
  location: { pathname: string; search: string }
  options: object
}

const GoogleAnalytics: FC<PropsType> = (props: PropsType) => {
  const [pathname] = useState(props.location.pathname)
  const [search] = useState(props.location.search)

  useEffect(() => {
    const isDifferentPathname = pathname !== props.location.pathname
    const isDifferentSearch = search !== props.location.search
    if (isDifferentPathname || isDifferentSearch) {
      const page = pathname.concat(search)
      const { location } = window
      ReactGA.set({
        page,
        location: `${location.origin}${page}`,
        ...props.options
      })
      ReactGA.pageview(page)
    }
  })

  return null
}

const RouteTracker = () => <Route component={GoogleAnalytics} />

const init = () => {
  const isGAEnabled = process.env.NODE_ENV === 'production'
  if (isGAEnabled) {
    ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID)
  }
  return isGAEnabled
}

export default { GoogleAnalytics, RouteTracker, init }
