import React, { useState, FC } from 'react'
import { RouteComponentProps } from 'react-router'
import { Storage } from 'aws-amplify'
import { Button, Card, Input, Modal, Col, Row, Upload, Icon } from 'antd'
import { FullWidthContainer } from 'components'
import { Formik } from 'formik'
import { LayoutType, Resource, ResourceType, Status } from 'generated/graphql'
import { generateCode, showNotification } from '../../utils'

type PropsType = RouteComponentProps & {
  history: History
  onSubmit: (e: any) => any
  initialValues?: Resource
}

const ContentFields: FC<PropsType> = (props: PropsType) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  /**
   * function to upload files to AWS S3 bucket, both images and resources
   * @param file the file being uploaded, image or document
   * @param setFieldValue function to update the respective field with the generated file name
   * @param fieldName name of the field in the db
   */
  async function uploadFile(file: any, setFieldValue: any, fieldName: string) {
    const fileKey = generateCode()
    return Storage.put(fileKey, file.file, {
      contentType: file.file.type
    })
      .then(() => {
        setLoading(false)
        setFieldValue(fieldName, fileKey.toString())
        showNotification({
          type: 'success',
          message: 'File Upload Success',
          description: 'The file has been uploaded successfully'
        })
      })
      .catch((err: any) => {
        setLoading(false)
        showNotification({
          type: 'error',
          message: 'File Upload Error',
          description: 'The file has not been uploaded, please check the file'
        })
      })
  }

  /**
   * Function to delete a file from AWS S3 bucket
   * @param file file name to be deleted
   * @param setFieldValue function to update the respective field value to null or -
   * @param fieldName name of the field to update in DB
   */
  function deleteFile(file: string, setFieldValue: any, fieldName: string) {
    Storage.remove(file, { level: 'public' })
      .then(() => {
        setFieldValue(fieldName, '-')
        showNotification({
          type: 'success',
          message: 'File Deleted',
          description: 'The file has been deleted successfully'
        })
      })
      .catch(() => {
        showNotification({
          type: 'error',
          message: 'File Not Deleted',
          description: 'There has been an error deleting file'
        })
      })
  }

  return (
    <Formik
      initialValues={{
        title: props.initialValues && props.initialValues.title ? props.initialValues.title : '',
        layout: LayoutType.Pdf,
        status: Status.Active,
        type: ResourceType.LegalDocument,
        document: props.initialValues && props.initialValues.document
      }}
      onSubmit={(values) => {
        if (props.initialValues && props.initialValues.id) {
          props.onSubmit({ variables: { input: { ...values, id: props.initialValues.id } } })
        } else {
          props.onSubmit({ variables: { input: values } })
        }
      }}
    >
      {({ handleChange, handleSubmit, setFieldValue, values: { title, document } }) => {
        return (
          <form>
            <Modal
              visible={modalVisible}
              onOk={() => handleSubmit()}
              onCancel={() => setModalVisible(false)}
              okText="Ok"
              cancelText="Cancel"
            >
              Are you sure you would like to publish this content?
            </Modal>
            <Row>
              <FullWidthContainer align="left">
                <Row type="flex" justify="space-between">
                  <Col sm={24} md={24} lg={24}>
                    <Card>
                      <Col sm={24} md={18} lg={18} style={{ paddingRight: '10px' }}>
                        <label>Title</label>
                        <Input
                          type="text"
                          name="title"
                          value={title}
                          id="title"
                          placeholder=""
                          onChange={handleChange}
                        />
                      </Col>
                      <Col sm={24} md={6} lg={6}>
                        <br />
                        {document && document !== '-' ? (
                          <Button
                            onClick={() => {
                              setLoading(true)
                              deleteFile(document, setFieldValue, 'document')
                            }}
                          >
                            <Icon type="delete" /> Remove Uploaded File
                          </Button>
                        ) : (
                          <Upload
                            accept={'application/pdf'}
                            customRequest={(e: any) => {
                              setLoading(true)
                              uploadFile(e, setFieldValue, 'document')
                            }}
                          >
                            <Button size="large">
                              <Icon type="upload" /> Click or drop to upload
                            </Button>
                          </Upload>
                        )}
                      </Col>
                    </Card>
                    <br />
                    <Card>
                      <Row>
                        <Button
                          onClick={() => props.history.push(`/admin/content-management`)}
                          style={{ marginRight: '10px' }}
                        >
                          Cancel
                        </Button>
                        <Button
                          disabled={loading}
                          type="default"
                          onClick={() => {
                            setModalVisible(true)
                          }}
                        >
                          Publish
                        </Button>
                        {props.initialValues && props.initialValues.id && (
                          <Button
                            disabled={loading}
                            style={{ marginLeft: '10px' }}
                            type="default"
                            onClick={() => {
                              setFieldValue('status', Status.Archived)
                              setModalVisible(true)
                            }}
                          >
                            Archive
                          </Button>
                        )}
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </FullWidthContainer>
            </Row>
          </form>
        )
      }}
    </Formik>
  )
}

export default ContentFields
