import React, { ReactNode, createContext, Component } from 'react'

interface IAppContextInterface {
  drawerOpen: boolean
  toggleDrawer: () => void
}

interface IProps {
  children: ReactNode
}

interface IState {
  drawerOpen: boolean
}

const ctxt = createContext<IAppContextInterface>({
  drawerOpen: true,
  toggleDrawer: () => {
    console.log('Not initialized')
  }
})

const AppContextProvider = ctxt.Provider
export const AppContextConsumer = ctxt.Consumer

export default class Provider extends Component<IProps, IState> {
  state: IState = {
    drawerOpen: true
  }

  toggleDrawer = () => {
    this.setState({ drawerOpen: !this.state.drawerOpen })
  }

  render() {
    return (
      <AppContextProvider
        value={{
          drawerOpen: this.state.drawerOpen,
          toggleDrawer: () => this.toggleDrawer()
        }}
      >
        {this.props.children}
      </AppContextProvider>
    )
  }
}
