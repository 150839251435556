import baseStyled, { ThemedStyledInterface } from 'styled-components'

const theme = {
  fontSizes: ['0.833rem', '1rem', '1.2rem', '1.44rem', '1.728rem', '2.074rem', '2.488rem'], // Golden ratio
  space: [0, 5, 10, 20, 40, 80, 160],
  fontFamily: 'Nunito Sans, Helvetica Neue, sans-serif',
  colors: {
    accent: '#5AC1F2',
    htmlBackground: '5AC1F2',
    inverseTextColor: '#CACACA',
    neutral: '#C9BDBC',
    primary: '#47915F',
    secondary: '#0F5C92',
    textPrimary: '#42596F',
    textSecondary: '#FFFFFF',
    transparent: '#00000000',
    transparentWhite: '#FFFFFF04',
    transparentBlack: '#00000004',
    error: '#D33243',
    coal: '#464E53',
    highlights: {
      yellowHighlight: '#FFFF00',
      greenHighlight: '#92E87B',
      blueHighlight: '#7BB2E8',
      pinkHighlight: '#DF7CB8',
      purpleHighlight: '#E0B3F0'
    },
    tileColors: [
      'linear-gradient(135deg, #D33243, #FF2727 70%)',
      'linear-gradient(135deg, #A5C600, #A3B936 70%)',
      'linear-gradient(135deg, #439EA2, #53C4C9 70%)',
      'linear-gradient(135deg, #50B794, #2BF5B0 70%)'
    ]
  },
  baseRadius: 4,
  baseBoxShadow: '0 4px 6px rgba(50,50,50, .11), 0 1px 3px rgba(0,0,0,.08)',
  baseTransition: 'all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)',
  maxContentWidth: 1200
}

export type Theme = typeof theme
const styled = baseStyled as ThemedStyledInterface<Theme>

export { theme, styled }
