import React, { Suspense, Fragment } from 'react'
// import { Redirect } from 'react-router';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
// Routes
import PageNotFound from 'containers/PageNotFound'
import PageLoader from 'components/PageLoader'
import { PUBLIC_ROUTES, CONSUMER_ROUTES, ADMIN_ROUTES, GENERAL_ROUTES } from './routes'
// Sidebar nav
import SideBar from '../components/Sidebar'
import { isLoggedIn, jwtInfo } from 'utils'
import GA from './GoogleAnalytics'

const AuthRoute = (props: any) => {
  const Comp = props.component
  const loggedInUser = isLoggedIn()
  // Redirect user if not logged in
  if (!loggedInUser) {
    window.location.href = '/sign-in'
  }

  // temporary function to check whether the user is on admin or consumer portal
  // Select Sidebar nav items based on user group
  const activeRoutes = jwtInfo().isAdmin ? ADMIN_ROUTES : CONSUMER_ROUTES

  return props.private ? (
    <SideBar
      navItems={activeRoutes}
      {...props}
      render={<Route render={(data) => <Comp {...data} {...props} />} />}
    >
      <Suspense fallback={<PageLoader />} />
    </SideBar>
  ) : (
    <Route render={(data) => <Comp {...data} {...props} />} />
  )
}

const adminOrConsumer = (props: any) => {
  if (jwtInfo().isAdmin) {
    return ADMIN_ROUTES.map((route, i) => {
      return <AuthRoute key={i} {...route} {...props} />
    })
  }
  return CONSUMER_ROUTES.map((route, i) => {
    return <AuthRoute key={i} {...route} {...props} />
  })
}

const Navigation = (props: any) => (
  <Router>
    <Suspense fallback={<PageLoader />}>
      {GA.init() && <GA.RouteTracker />}
      <Fragment>
        <Switch>
          {PUBLIC_ROUTES.map((route, i) => {
            return <Route key={i} {...route} {...props} />
          })}
          {adminOrConsumer(props)}
          {GENERAL_ROUTES.map((route, i) => {
            return <AuthRoute key={i} {...route} {...props} />
          })}
          <Route component={PageNotFound} />
        </Switch>
      </Fragment>
    </Suspense>
  </Router>
)

export default Navigation
