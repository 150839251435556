// @ts-check
import { initSchema } from '@aws-amplify/datastore'
import { schema } from './schema'

const UserRole = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  ADMIN: 'ADMIN',
  AGENT: 'AGENT',
  CONSUMER: 'CONSUMER',
  VISITOR: 'VISITOR'
}

const Status = {
  ACTIVE: 'ACTIVE',
  DRAFT: 'DRAFT',
  ARCHIVED: 'ARCHIVED',
  SUSPENDED: 'SUSPENDED'
}

const Grade = {
  GRADE_R: 'GRADE_R',
  GRADE_1: 'GRADE_1',
  GRADE_2: 'GRADE_2',
  GRADE_3: 'GRADE_3',
  GRADE_4: 'GRADE_4',
  GRADE_5: 'GRADE_5',
  GRADE_6: 'GRADE_6',
  GRADE_7: 'GRADE_7',
  GRADE_8: 'GRADE_8',
  GRADE_9: 'GRADE_9',
  GRADE_10: 'GRADE_10',
  GRADE_11: 'GRADE_11',
  GRADE_12: 'GRADE_12',
  GENERAL: 'GENERAL'
}

const Subject = {
  MATHEMATICS: 'MATHEMATICS',
  MATHEMATICAL_LITERACY: 'MATHEMATICAL_LITERACY',
  SOCIAL_SCIENCES: 'SOCIAL_SCIENCES',
  NATURAL_SCIENCES: 'NATURAL_SCIENCES',
  NATURAL_SCIENCES_AND_TECHNOLOGY: 'NATURAL_SCIENCES_AND_TECHNOLOGY',
  SOSIALE_WESTENSKAPPE: 'SOSIALE_WESTENSKAPPE',
  ECONOMIC_AND_MANAGEMENT_SCIENCES: 'ECONOMIC_AND_MANAGEMENT_SCIENCES',
  HISTORY: 'HISTORY',
  GESKIEDENIS: 'GESKIEDENIS',
  LEWENSORIENTERING: 'LEWENSORIENTERING',
  LIFE_SKILLS: 'LIFE_SKILLS',
  ACCOUNTING: 'ACCOUNTING',
  REKENINGKUNDE: 'REKENINGKUNDE',
  SEPEDI_HOME_LANGUAGE: 'SEPEDI_HOME_LANGUAGE',
  SESOTHO_HOME_LANGUAGE: 'SESOTHO_HOME_LANGUAGE',
  SETSWANA_HOME_LANGUAGE: 'SETSWANA_HOME_LANGUAGE',
  SISWATI_HOME_LANGUAGE: 'SISWATI_HOME_LANGUAGE',
  TSHIVENDA_HOME_LANGUAGE: 'TSHIVENDA_HOME_LANGUAGE',
  XITSONGA_HOME_LANGUAGE: 'XITSONGA_HOME_LANGUAGE',
  AFRIKAANS_HOME_LANGUAGE: 'AFRIKAANS_HOME_LANGUAGE',
  ENGLISH_HOME_LANGUAGE: 'ENGLISH_HOME_LANGUAGE',
  ENGLISH_FAL: 'ENGLISH_FAL',
  ISINDEBELE_HOME_LANGUAGE: 'ISINDEBELE_HOME_LANGUAGE',
  ISIXHOSA_HOME_LANGUAGE: 'ISIXHOSA_HOME_LANGUAGE',
  ISIZULU_HOME_LANGUAGE: 'ISIZULU_HOME_LANGUAGE',
  NULL: 'NULL'
}

const Genre = {
  NOVELS: 'NOVELS',
  POETRY: 'POETRY',
  SHORT_STORIES: 'SHORT_STORIES',
  DRAMA: 'DRAMA',
  FOLKLORE: 'FOLKLORE',
  AFRICAN_PRINT: 'AFRICAN_PRINT',
  NULL: 'NULL'
}

const LayoutType = {
  REFLOWABLE: 'REFLOWABLE',
  PDF: 'PDF',
  EPUB: 'EPUB'
}

const Language = {
  SEPEDI: 'SEPEDI',
  SESOTHO: 'SESOTHO',
  SETSWANA: 'SETSWANA',
  SISWATI: 'SISWATI',
  TSHIVENDA: 'TSHIVENDA',
  XITSONGA: 'XITSONGA',
  AFRIKAANS: 'AFRIKAANS',
  ENGLISH: 'ENGLISH',
  ISINDEBELE: 'ISINDEBELE',
  ISIXHOSA: 'ISIXHOSA',
  ISIZULU: 'ISIZULU'
}

const ResourceType = {
  TEXTBOOK: 'TEXTBOOK',
  LITERATURE: 'LITERATURE',
  LEGAL_DOCUMENT: 'LEGAL_DOCUMENT',
  LEARNING_MATERIAL: 'LEARNING_MATERIAL'
}

const TransactionStatus = {
  PENDING: 'PENDING',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED'
}

const LiteratureCategory = {
  CHILDREN: 'CHILDREN',
  YOUNG_ADULT: 'YOUNG_ADULT',
  ADULT: 'ADULT'
}

const AnnotationType = {
  HIGHLIGHT: 'HIGHLIGHT',
  UNDERLINE: 'UNDERLINE'
}

const {
  User,
  UserCartConn,
  Resource,
  Unit,
  ResourceVoucherConn,
  Voucher,
  Transaction,
  TransactionResourceConn,
  Faq,
  Annotation
} = initSchema(schema)

export {
  User,
  UserCartConn,
  Resource,
  Unit,
  ResourceVoucherConn,
  Voucher,
  Transaction,
  TransactionResourceConn,
  Faq,
  Annotation,
  UserRole,
  Status,
  Grade,
  Subject,
  Genre,
  LayoutType,
  Language,
  ResourceType,
  TransactionStatus,
  LiteratureCategory,
  AnnotationType
}
