import React, { FC } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

interface IProps {
  image: string
  width?: string
}

const Logo = styled.img`
  display: inline-block;
  max-width: 100%;
  height: auto;
`

const LogoWrapper: FC<IProps> = (props: IProps) => {
  return (
    <Link to="/">
      <Logo src={props.image} width={props.width || 'auto'}  alt="Logo" />
    </Link>
  )
}

export default LogoWrapper
