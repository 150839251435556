import { ResourceType } from 'generated/graphql'

export const textbookOrLiterature = (pathname: string): string => {
  if (pathname.toLocaleLowerCase().includes(ResourceType.Textbook.toLocaleLowerCase())) {
    return 'textbook'
  }
  if (pathname.toLocaleLowerCase().includes(ResourceType.Literature.toLocaleLowerCase())) {
    return 'literature'
  }
  return ''
}
