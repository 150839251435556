import React, { FC } from 'react'
import styled from 'styled-components'
// Presentation/UI
import { theme } from 'theme'
import { Loader } from '../../components'
// Themes

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: ${(props: { height?: string }) => (props.height ? `${props.height}` : 'auto')};
  background: ${theme.colors.htmlBackground};
`

const PageLoader: FC<{ height?: string }> = (props) => {
  return (
    <Wrapper height={props.height}>
      <Loader size={40} color={theme.colors.primary} />
    </Wrapper>
  )
}

export default PageLoader
