import React, { Component, ReactNode } from 'react'
import PageWrapper from '../Layouts/PageWrapper'
import LottieWrapper from '../Anim/LottieWrapper'
import StyledButton from '../Styled/Button'
import { theme } from '../../theme'

interface IProps {
  children: ReactNode
}

class ErrorBoundary extends Component<IProps> {
  render() {
    return (
      <PageWrapper>
        <LottieWrapper
          width={400}
          height={'auto'}
          loop={true}
          anim={require('../../assets/lottieFiles/me_at_office.json')}
        />
        Oops, looks like something went wrong. Our team has been notified of this error and will
        have it resolved as soon as possible.
        <StyledButton
          label="Go back"
          type="primary"
          margin="1.5rem 2rem"
          color={theme.colors.textSecondary}
          background={theme.colors.primary}
          onClick={() => window.history.back()}
        />
      </PageWrapper>
    )
  }
}

export default ErrorBoundary
