import jwtDecode from 'jwt-decode'
import { AUTH_USER_TOKEN_KEY } from '../constants'
import { UserRole } from 'generated/graphql'

type JwtInfo = {
  valid: boolean
  userId: string
  userRole: string
  isAdmin: boolean
  error?: string
}

export const jwtInfo = (jwt?: any): JwtInfo => {
  let token: string | undefined | null = jwt
  if (!jwt) {
    token = window.localStorage.getItem(AUTH_USER_TOKEN_KEY)
  }
  try {
    // @ts-ignore after react scripts changes
    const decodedJwt: any = jwtDecode(token)
    const userRole =
      decodedJwt && decodedJwt['cognito:groups'] ? decodedJwt['cognito:groups'][0] : 'NO_GROUP'
    return {
      valid: Date.now() <= decodedJwt.exp * 1000,
      userId: decodedJwt.sub,
      userRole,
      isAdmin:
        userRole === UserRole.Admin ||
        userRole === UserRole.SuperAdmin ||
        userRole === UserRole.Agent
    }
  } catch (e) {
    return {
      valid: false,
      userId: '',
      userRole: 'VISITOR',
      isAdmin: false,
      error: 'There was an error returning your data'
    }
  }
}
